import { useEffect, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import { useDispatch, useSelector } from "react-redux";
import webBanner from "../../images/webBanner.jpg";
import { getBannerFrontend } from "../../reducers/frontEndReducer";
import { handleContextMenu } from "../../const";
import { Col, Row } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

function WebSlider() {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  useEffect(() => {
    setLoading(true);
    dispatch(getBannerFrontend());
      setLoading(false)
  }, []);
  const { getBannerFrontendData } = useSelector(
    (state) => state.frontEndReducer
  );
  return (
    <>
      {loading ? (
         <Row>
          <Col md={12} className="text-center">
            <Skeleton style={{ width: "100%", height: "30vh" }} />
          </Col>
        </Row>
      ) : (
        <Carousel variant="light" className="my-3" id="webslider">
          {!!getBannerFrontendData?.list &&
            getBannerFrontendData?.list.map((banner, index) => {
              return (
                <Carousel.Item key={index}>
                  <img
                    className="d-block w-100 imgbanner "
                    src={banner?.image}
                    alt={banner?.title}
                    onContextMenu={handleContextMenu}
                  />
                </Carousel.Item>
              );
            })}
        </Carousel>
      )}
    </>
  );
}

export default WebSlider;
