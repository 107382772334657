import Pagination from 'react-bootstrap/Pagination';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

function NumberPagination({ pageCount, currentPage, onPageChange, api, pageurl, filters, productId }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handlePageChange = (newPage) => {
        onPageChange(newPage);
        dispatch(api({
            page: newPage,
            ...filters
        }));
        window.scrollTo(0, 0);
        const queryParams = new URLSearchParams();
        queryParams.append("page", newPage);
        Object.entries(filters).forEach(([key, value]) => {
            if (value !== null && value !== undefined) {
                queryParams.append(key, value);
            }
        });
        // if(productId){
        //     navigate(`${pageurl}?ref_=${productId}&page=${newPage}`);
        // }else{
        //     navigate(`${pageurl}?page=${newPage}`);
        // }
        if (productId) {
            navigate(`${pageurl}?ref_=${productId}&${queryParams.toString()}`);
        } else {
            navigate(`${pageurl}?${queryParams.toString()}`);
        }
    };

    const renderPaginationItems = () => {
        const items = []; 

        if (pageCount <= 7) {
            // If there are 7 or fewer pages, show all
            for (let i = 1; i <= pageCount; i++) {
                items.push(
                    <Pagination.Item
                        key={i}
                        active={+currentPage === i}
                        onClick={() => handlePageChange(i)}
                    >
                        {i}
                    </Pagination.Item>
                );
            }
        } else {
            // Always show the first page
            items.push(
                <Pagination.Item
                    key={1}
                    active={+currentPage === 1}
                    onClick={() => handlePageChange(1)}
                >
                    1
                </Pagination.Item>
            );

            // Show an ellipsis if the current page is far from the start
            if (+currentPage > 4) {
                items.push(<Pagination.Ellipsis key="start-ellipsis" />);
            }

            // Show pages around the current page
            for (let i = Math.max(2, +currentPage - 2); i <= Math.min(pageCount - 1, +currentPage + 2); i++) {
                items.push(
                    <Pagination.Item
                        key={i}
                        active={+currentPage === i}
                        onClick={() => handlePageChange(i)}
                    >
                        {i}
                    </Pagination.Item>
                );
            }

            // Show an ellipsis if the current page is far from the end
            if (+currentPage < pageCount - 3) {
                items.push(<Pagination.Ellipsis key="end-ellipsis" />);
            }

            // Always show the last page
            items.push(
                <Pagination.Item
                    key={pageCount}
                    active={+currentPage === pageCount}
                    onClick={() => handlePageChange(pageCount)}
                >
                    {pageCount}
                </Pagination.Item>
            );
        }

        return items;
    };

    return (
        <Pagination className='justify-content-center'>
            <Pagination.Prev
                onClick={() => {
                    if (+currentPage > 1) {
                        handlePageChange(+currentPage - 1);
                    }
                }}
                disabled={+currentPage === 1}
            />
            {renderPaginationItems()}
            <Pagination.Next
                onClick={() => {
                    if (+currentPage < pageCount) {
                        handlePageChange(+currentPage + 1);
                    }
                }}
                disabled={+currentPage === pageCount}
            />
        </Pagination>
    );
}

export default NumberPagination;
