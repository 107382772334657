import React, { useEffect, useState } from "react";
import Footer from "../Sub/Footer";
import webBanner from "../../images/webBanner.jpg";
import CommonHeader from "../Sub/CommonHeader";
import Banner from "../Sub/Banner";
import { Form, Row, Col, Button } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import GotoTop from "../../Components/GotoTop";
import { Helmet } from "react-helmet";
import {baseUrl } from "../../const";
import contactBanner from '../../images/contact-banner.jpg'

const schema = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().email().required(),
  phone: yup.string().min(10).max(10).required(),
  textarea: yup.string().required(),
});

const Contact = () => {
  useEffect(() => {
  }, []);
  return (
    <>
    <Helmet>
        <title>Ranbazaar - Contact</title>
        <meta name="description" content="Ranbazaar Private Limited"/>
        <link rel="canonical" href={`${baseUrl}contact`} />
        <meta property="og:title" content="Ranbazaar - Contact"/>
        <meta property="og:site_name" content="Ranbazaar" />
        <meta property="og:url" content={`${baseUrl}contact`} />
        <meta property="og:description" content="Ranbazaar Private Limited"/>
        <meta property="og:type" content="website" />
        <meta property="og:image" content="/ranbazar.png"/>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@ranbazaar" />
        <meta name="twitter:creator" content="@ranbazaar" />
        <meta name="twitter:title" content="Ranbazaar - Contact" />
        <meta property="twitter:description" content="Ranbazaar Private Limited" />
        <meta name="twitter:image" content="/ranbazar.png" />
      </Helmet>
      <CommonHeader />
      <div>
        <Banner bannerImg={contactBanner} />
      </div>
      <div className="container-fluid">
        <div className="page-main p-5 mbl-pedding-10">
        {/* <div className="section__head d-md-flex justify-content-between mb-40"><div className="section__title">
            <h1>Contact <span>Us</span></h1></div>
        </div> */}
          <Row className="align-item-center">
            <Col md={6} className="hideMb">
                <div>
                    <h3><b>Address</b></h3>
                    <p>FIRST FLOOR, SCO-104, POCKET A, SECTOR 14 <br />

                        Hisar, Haryana (125001), India</p>
                </div>
                <div>
                    <h3><b>Mobile No.</b></h3>
                    <p>+91 93066 39516</p>
                </div>
                <div>
                    <h3><b>Email</b></h3>
                    <p>info@ranbazaar.com</p>
                </div>
            </Col>
            <Col md={6} className="box-shadows mbl-pedding-10 p-5">
            <div className="border-y mb-4">
                <h2 className="mb-0">
                Get In Touch
                </h2>
              </div>
              <Formik
                validationSchema={schema}
                onSubmit={async (values, { resetForm }) => {
                //   dispatch(orderCreate(values));
                  resetForm({ values: "" });
                }}
                initialValues={{
                    name: "",
                    email: "",
                    phone: "",
                    password: "",
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  touched,
                  values,
                  errors,
                }) => (
                  <Form className="container" onSubmit={handleSubmit}>
                    <Row className="mb-3">
                      <Col md={6} sm={12}>
                        <Form.Group controlId="validationFormik01">
                          <Form.Label>Name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Name"
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                            isInvalid={!!errors.name}
                            className={errors.name && touched.name && "errors"}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.name}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md={6} sm={12}>
                        <Form.Group controlId="validationFormik01">
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            type="email"
                            placeholder="Email"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            isInvalid={!!errors.email}
                            className={errors.email && touched.email && "errors"}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.email}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col md={12} sm={12}>
                        <Form.Group controlId="validationFormik01">
                          <Form.Label>Phone</Form.Label>
                          <Form.Control
                            type="number"
                            placeholder="Phone"
                            name="phone"
                            value={values.phone}
                            onChange={handleChange}
                            isInvalid={!!errors.phone}
                            className={errors.phone && touched.phone && "errors"}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.phone}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                    <Col md={12} sm={12}>
                      <Form.Group controlId="validationFormik01">
                        <Form.Label>Enter Enquiry</Form.Label>
                        <Form.Control
                          as="textarea" // Use 'as' prop to specify textarea
                          placeholder="Enter Your Enquiry......"
                          name="textarea"
                          value={values.textarea}
                          onChange={handleChange}
                          isInvalid={!!errors.textarea}
                          className={errors.textarea && touched.textarea && "errors"}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.textarea}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    </Row>
                    <Button variant="success" className="bg-thememain" type="submit">
                    <i className="bi bi-person-circle"></i> Contact Us
                    </Button>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>
          <Row className="mt-4">
          <iframe src="https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d448652.9244520943!2d77.24932326538087!3d28.536684596872966!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1724914489731!5m2!1sen!2sin" style={{width:"100%", height:"400", style:"border:0"}}  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </Row>
        </div>
      </div>
      <GotoTop />
      <Footer />
    </>
  );
};

export default Contact;
