import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SideMenu from "../../Components/SideMenu";
import {
  addPackage,
  deletePackage,
  getPackage,
  updatePackage,
} from "../../reducers/commonReducer";
import { Button} from "react-bootstrap";
import { home } from "../../const";
import PackageAction from "./PackageAction";

const PackageList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (localStorage.getItem("slug") === "admin" ? !localStorage.getItem("x-admin-token") : !localStorage.getItem("x-auth-token")) {
      navigate(home);
    }
    dispatch(getPackage());
  }, []);

  const { getPackageList } = useSelector((state) => state.commonReducer);
  return (
    <>
      <SideMenu />
      <div className="mt-extra content container-fluid">
        <PackageAction
          title={"Add Package"}
          popUpTitle="Add New Package"
          api={addPackage}
        />
        <table className="table mt-2">
          <thead className="normalplace">
            <tr className="tables">
              <th className="over-col-size">Sr.</th>
              <th className="over-col-size">Name</th>
              <th className="over-col-size">Length</th>
              <th className="over-col-size">Width</th>
              <th className="over-col-size">Height</th>
              <th className="over-col-size">Status</th>
              <th className="over-col-size">Action</th>
            </tr>
          </thead>
          <tbody className="tbody-table">
            {!!getPackageList &&
              getPackageList?.map((data, index) => {
                return (
                  <React.Fragment key={index}>
                    <tr key={data?._id}>
                      <td>{index + 1}.</td>
                      <td>{data?.name}</td>
                      <td>{data?.length}</td>
                      <td>{data?.width}</td>
                      <td>{data?.height}</td>
                      <td>{data?.status ? <span className="text-success"><b>Active</b></span> : <span className="text-danger"><b>InActive</b></span>}</td>
                      <td>
                        <PackageAction
                          title={<i className="bi bi-pencil-square"></i>}
                          popUpTitle="Update Package"
                          api={updatePackage}
                          id={data?.id}
                          name={data?.name}
                          length={data?.length}
                          width={data?.width}
                          height={data?.height}
                          status={data?.status}
                        />
                        <Button
                          variant="danger"
                          className="mx-1"
                          onClick={async () => {
                            const apirsp = await dispatch(deletePackage(data?.id));
                            if(apirsp?.payload?.status){
                               dispatch(getPackage());
                            }
                          }}
                        >
                          <i className="bi bi-trash3-fill"></i>
                        </Button>
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default PackageList;
