import React, { Fragment, useState } from "react";
import Tab from "react-bootstrap/Tab";
import { useDispatch, useSelector } from "react-redux";
import Tabs from "react-bootstrap/Tabs";
import { Col, Row, Button } from "react-bootstrap";
import { openNewTab, productRating } from "../../const";
import moment from "moment";
import { getProductReviews } from "../../reducers/orderDetailsReducer";

const ProductTab = (props) => {
  const dispatch = useDispatch();
  const [key, setKey] = useState("Details");
  const [page, setPage] = useState(1);
  const { productDesc, productid} = props;
  const { getProductReviewsList, productReviewsListBlank } = useSelector((state) => state.orderDetailReducer);
  const setTabs = (k) => {
    setKey(k);
    // key == "Details" &&
    //   dispatch(
    //     getProductReviews({
    //       product: getSingleProductData?.id,
    //     })
    //   );
  };

  return (
    <>
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={setTabs}
        className="mb-3"
      >
        <Tab
          eventKey="Details"
          title="Product Description"
          dangerouslySetInnerHTML={{ __html: productDesc }}
        ></Tab>
        <Tab
          eventKey="Review"
          title="Review"
        >
          {!!getProductReviewsList && getProductReviewsList?.map((review, index) => (
            <Fragment key={index}>
              <Row className="mt-2">
                <Col md={12} xs={12} sm={12}><h6><b><i className="bi bi-person-circle"></i> {review?.reviewer_name ? review?.reviewer_name : review?.name}</b></h6></Col>
                <Col md={12} xs={12} sm={12}><ul>
                {review?.rating > 0 ? productRating(Math.round(review?.rating)) : null}</ul></Col>
                <Col md={12} xs={12} sm={12}><p className="mb-0 f-18"><b>{review?.title}</b></p></Col>
                <Col md={12} xs={12} sm={12}><p className="mb-0"><b>{moment(review?.review_date ? review?.review_date : review?.createdAt).format("MMM Do YY")}</b></p></Col>
                <Col md={12} xs={12} sm={12}><p className="mb-0">{review?.body}</p></Col>
                <Col md={12} xs={12} sm={12}>
                {
                  (review?.reviewType === "content" && (review?.picture_urls ? review?.picture_urls : review?.images)?.map((img, imgIndex) => <img className="w-100px" onClick={() => openNewTab(img)} key={imgIndex} src={img} alt={review?.title}  loading="lazy"/>)) ||
                  (review?.reviewType === "video" && <video width={"180"} height={"300"} src={review?.video} controls></video>)
                }
                </Col>
              </Row>
              <hr/>
            </Fragment>
          ))}
          {!!productReviewsListBlank && productReviewsListBlank.length > 0 ? (
          <Row>
            <Col>
              <Button
              className="w-100"
                onClick={() => {
                  setPage(page + 1);
                  dispatch(
                    getProductReviews({
                      page: page + 1,
                      product: productid
                    })
                  );
                }}
              >
                Load More
              </Button>
            </Col>
          </Row>
        ) : (
          <p className="text-center">
            <b>No record found 😔.</b>
          </p>
        )}
        </Tab>
      </Tabs>
    </>
  );
};

export default ProductTab;
