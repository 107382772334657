import React from "react";
import { handleContextMenu } from "../../const";

const Banner = (props) => {
  return (
    <>
        <img src={props?.bannerImg} alt="Ranbazzar" onContextMenu={handleContextMenu} loading="lazy"/>
    </>
  );
};

export default Banner;
