import React from 'react'
import { Bar, Line } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import {CategoryScale} from 'chart.js'; 
Chart.register(CategoryScale);

export default function DashboardGraph({graphData, title, chartType}) {
    const successOrderGraph = {
        labels: chartType ? graphData?.map((item) => item?.name) : ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        datasets: [
          {
            label: title,
            data: chartType ? graphData?.map((item) => item?.quantity) : graphData?.map((amount) => amount),
            fill: true,
            backgroundColor: "rgb(242 68 98 / 22%)",
            borderColor: "#f24462",
            tension: 0.5,
            borderWidth: 1.5,
            pointBorderWidth:0,
            pointRadius:0
          }
        ]
      };
  return (
    chartType ? <Bar data={successOrderGraph} /> : <Line data={successOrderGraph} />
  )
}
