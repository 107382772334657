import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollHeight({loading}) {
    const location = useLocation();
  //current location
  useEffect(() => {
    if(!loading){
      const handleScroll = () => {
        const scrollPosition = window.scrollY;
        localStorage.setItem(`scroll-height${location.pathname}`, scrollPosition); 
      }; 
      
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, [location.pathname, loading]);

  useEffect(() =>{
    const savedScrollPosition = localStorage.getItem(`scroll-height${location.pathname}`);
    if(!loading){
        setTimeout(() => {
            window.scrollTo(0, +savedScrollPosition);
            localStorage.removeItem(`scroll-height${location.pathname}`)
          },1000); 
     
    }
  }, [location, loading])
 //end current location
  return null;
}
