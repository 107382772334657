import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  adminOrdersExport,
  adminPendingOrders,
  resetAdminOrderList,
} from "../../reducers/orderDetailsReducer";
import SideMenu from "../../Components/SideMenu";
import moment from "moment";
import OrderUpdate from "./OrderUpdate";
import { Button, Row, Form, InputGroup, Col } from "react-bootstrap";
import { orderDetailsUrl } from "../../const";

const PendingOrders = () => {
  const [search, setSearch] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [orderStatus, setOrderStatus] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedOrders, setSelectedOrders] = useState([]);
  const queryParameters = new URLSearchParams(window.location.search);
  const orderId = queryParameters.get("orderid");
  const userId = queryParameters.get("userid");

  useEffect(() => {
    dispatch(resetAdminOrderList());
  },[])
  useEffect(() => {
    dispatch(resetAdminOrderList());
    let timeOut = setTimeout(() => {
      dispatch(
        adminPendingOrders({
          search: search,
          userId,
        })
      );
    }, 800);
    return () => clearTimeout(timeOut);
  }, [search]);
  // useEffect(() => {
  //   dispatch(getTrackers());
  // }, [orderId, userId]);

  const { adminPendingOrdersData, adminPendingOrdersDataBlank } = useSelector((state) => state.orderDetailReducer);
  let orderQty = 0;
  // combo selection
  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const allOrderIds = adminPendingOrdersData?.map((order) => order.id);
      setSelectedOrders(allOrderIds);
    } else {
      setSelectedOrders([]);
    }
  };
  
  const handleSelectOrder = (event, orderId) => {
    if (event.target.checked) {
      setSelectedOrders([...selectedOrders, orderId]);
    } else {
      setSelectedOrders(selectedOrders.filter((id) => id !== orderId));
    }
  };
  return (
    <>
      <SideMenu />
        <div className="mt-extra content container-fluid">
          <Row className="mb-1">
            <Col md={4} className="mt-4">
              <InputGroup className="search-wrapper">
                <InputGroup.Text id="inputGroup-sizing-default"className="custom-search-icon">
                  <i className="bi bi-search"></i>
                </InputGroup.Text>
                <Form.Control
                className="animated-search-input"
                  placeholder="Search anything..."
                  aria-label="Default"
                  aria-describedby="inputGroup-sizing-default"
                  onChange={(e) => setSearch(e.target.value)}
                />
              </InputGroup>
            </Col>
            <Col md={2}>
              <Form.Group>
                <Form.Label>Start Date</Form.Label>
                <Form.Control
                className="animated-search-input"
                  type="date"
                  placeholder="Start Date"
                  name="startDate"
                  max={moment().format("YYYY-MM-DD")}
                  onChange={(e) => {
                    dispatch(resetAdminOrderList());
                    dispatch(
                      adminPendingOrders({
                        userId,
                        endDate,
                        startDate: e.target.value,
                        search,
                        page,
                      })
                    );
                    dispatch(
                      adminOrdersExport({
                        userId,
                        endDate,
                        startDate: e.target.value,
                      })
                    );
                    setStartDate(e.target.value);
                  }}
                />
              </Form.Group>
            </Col>
            <Col md={2}>
              <Form.Group>
                <Form.Label>End Date</Form.Label>
                <Form.Control
                className="animated-search-input"
                  type="date"
                  placeholder="End Date"
                  name="endtDate"
                  max={moment().format("YYYY-MM-DD")}
                  onChange={(e) => {
                    dispatch(resetAdminOrderList());
                    dispatch(
                      adminPendingOrders({
                        search: search,
                        userId,
                        endDate: e.target.value,
                        startDate,
                        search,
                        page,
                      })
                    );
                    dispatch(
                      adminOrdersExport({
                        userId,
                        endDate: e.target.value,
                        startDate,
                      })
                    );
                    setEndDate(e.target.value);
                  }}
                />
              </Form.Group>
            </Col>
            <Col md={2} className="mt-4">
              <Form.Select
                onChange={(e) => {
                  dispatch(resetAdminOrderList());
                  setOrderStatus(+e.target.value);
                  dispatch(
                    adminPendingOrders({
                      status:
                        e.target.value === "all" ? "all" : +e.target.value,
                      page,
                      startDate,
                      endDate,
                    })
                  );
                  dispatch(
                    adminOrdersExport({
                      status:
                        e.target.value === "all" ? "all" : +e.target.value,
                      endDate,
                      startDate,
                    })
                  );
                }}
                aria-label="Default select example"
                className="fancyy-select-dropdown"
              >
                <option value={"all"}>Select Status</option>
                <option value={"all"} className="text-dark">
                  <b>All</b>
                </option>
                <option value={0} className="text-warning">
                  <b>Pending</b>
                </option>
                <option value={1} className="text-danger">
                  <b>Cancel</b>
                </option>
              </Form.Select>
            </Col>
            <Col md={2} className="text-end mt-4">
              <Button variant="danger" className="animatedd-clear-btn" onClick={() => window.location.reload()}>
                Clear
              </Button>
            </Col>
            <Col md={1} className="mt-4">
            </Col>
          </Row>
          <table className="table w-100">
            <thead>
              <tr className="tables">
                {/* <th>
                <input
                  type="checkbox"
                  onChange={handleSelectAll}
                  checked={adminPendingOrdersData.length > 0 && selectedOrders.length === adminPendingOrdersData.length}
                />
                </th> */}
                <th>Order</th>
                {localStorage.getItem("username") == "master@example.com" && <th>AdminOrderId</th>}
                <th>Items</th>
                <th>Date</th>
                <th>Total</th>
                <th>Payment Status</th>
                <th>Customer</th>
                <th>Source</th>
                <th>Delivery Method</th>
                <th>Order Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody className="tbody-table">
              {!!adminPendingOrdersData &&
                adminPendingOrdersData?.map((orders, index) => {
                  let orderItemQty = 0;
                  orders?.items?.forEach((item) => {
                    orderItemQty += item.quantity;
                  });
                  // orderQty += orderItemQty;
                  return (
                    <tr key={index}>
                      {/* <td>
                        <input
                          type="checkbox"
                          onChange={(e) => handleSelectOrder(e, orders.id)}
                          checked={selectedOrders.includes(orders.id)}
                        />
                      </td> */}
                      <td className="button-pointer" onClick={() => navigate(`${orderDetailsUrl}?orderid=${orders?.id}&pending=true`)}>
                          {orders?.successid ? orders?.successid : orders?.orderid}
                      </td>
                      {localStorage.getItem("username") == "master@example.com" && <td>{orders?.orderid}</td>}
                      <td className="button-pointer" onClick={() => navigate(`${orderDetailsUrl}?orderid=${orders?.id}&pending=true`)}>{orderItemQty}</td>
                      <td className="button-pointer" onClick={() => navigate(`${orderDetailsUrl}?orderid=${orders?.id}&pending=true`)}>
                        {moment(orders?.createdAt).format("D MMM [at] h:mm a")}
                      </td>
                      {/* <td>₹ {orders?.payment}</td>
                      <td><span className={`rounded px-2 f-13 ${orders?.paymenttype === "PENDING" && `bg-warning`} ${orders?.paymenttype === "COD" && `bg-info`}`}>{((orders?.paymenttype === "Prepaid") ? "Paid" : orders?.paymenttype)}</span></td> */}
                      <td className="button-pointer" onClick={() => navigate(`${orderDetailsUrl}?orderid=${orders?.id}&pending=true`)}>₹ {(orders?.totalPaybleAmount + orders?.codChargesAmount + orders?.shippingCost)?.toFixed(0)}</td>
                      <td className="button-pointer" onClick={() => navigate(`${orderDetailsUrl}?orderid=${orders?.id}&pending=true`)}><span className={`rounded px-2 f-13 ${orders?.paymenttype === "PENDING" && `bg-warning`} ${orders?.paymenttype === "COD" && `bg-info`}`}>{((orders?.paymenttype === "Prepaid") && "Paid") || ((orders?.paymenttype === "COD") && "UnPaid") || ((orders?.paymenttype === "PENDING") && "PENDING")}</span></td>
                      <td className="button-pointer" onClick={() => navigate(`${orderDetailsUrl}?orderid=${orders?.id}&pending=true`)}>
                        {orders?.shippingAddress?.firstName
                          ? `${orders?.shippingAddress?.firstName} ${orders?.shippingAddress?.lastName || ''}`
                          : "No Customer"}
                      </td>
                      <td>
                        {orders?.utm_source} / {orders?.utm_Medium}
                      </td>
                      <td onClick={() => navigate(`${orderDetailsUrl}?orderid=${orders?.id}&pending=true`)} className="f-13 button-pointer">Standard ({orders?.deliveryStatus?.Status ? orders?.deliveryStatus?.Status : orders?.paymenttype})</td>
                      <td>
                       <span className= {
                          (orders?.status === 0 && "text-dark bg-warning rounded px-2 f-13 ") ||
                          (orders?.status === 1 && "text-dark bg-danger rounded px-2 f-13") ||
                          (orders?.status === 2 && "text-dark bg-primary rounded px-2 f-13") ||
                          (orders?.status === 3 && "text-dark bg-secondary rounded px-2 f-13") ||
                          (orders?.status === 4 && "text-white bg-dark rounded px-2 f-13") ||
                          (orders?.status === 5 && "text-dark bg-info rounded px-2 f-13") ||
                          (orders?.status === 6 && "text-dark bg-success rounded px-2 f-13")
                        }
                      >{/* 0 pending,1 cancel, 2 processing, 3 verify 4 In_Transit , 5 shipping, 6 completed */}
                        <b className="td1 ">
                          {(orders?.status === 0 && "Pending") ||
                            (orders?.status === 1 && "Cancel") ||
                            (orders?.status === 2 && "Processing") ||
                            (orders?.status === 3 && "Verify") ||
                            (orders?.status === 4 && "In_Transit") ||
                            (orders?.status === 5 && "Shipping") ||
                            (orders?.status === 6 && "Completed")}
                        </b>
                        </span>
                      </td>
                      <td>
                        <Link to={`${orderDetailsUrl}?orderid=${orders?.id}&pending=true`} 
                        // onClick={() =>dispatch(
                        //   singleOrder({ id: orderId })
                        // )}
                        aria-label="View"
                        >
                          <Button className="m-1 bg-thememain btn-mbl-text">
                            <i className="bi bi-eye-fill"></i>
                          </Button>
                        </Link>
                        <OrderUpdate
                          title={"Update Order Status"}
                          orderStatus={orders?.status}
                          orderId={orders?.id}
                          listingApi={adminPendingOrders}
                          pendingOrder={true}
                          paymentStatusUpdate={orders?.paymenttype}
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          {!!adminPendingOrdersDataBlank && adminPendingOrdersDataBlank?.length > 0 ? (
            <Row>
              <Button
                onClick={() => {
                  setPage(page + 1);
                  dispatch(
                    adminPendingOrders({
                      search: search,
                      page: page + 1,
                      status: orderStatus,
                      startDate,
                      endDate,
                    })
                  );
                }}
              >
                Load More
              </Button>
            </Row>
          ) : (
            <p className="text-center">
              <b>No record found 😔.</b>
            </p>
          )}
        </div>
     
    </>
  );
};

export default PendingOrders;
