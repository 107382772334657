import React, { useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserAddressDetails,
  updateUserAddress,
  zipcodeCityState, orderAddressUpdate
} from "../../../reducers/commonReducer";
import { singleOrder } from "../../../reducers/orderDetailsReducer";

const schema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string(),
  email: yup.string(),
  phone: yup
    .string()
    .matches(/^[0-9]+$/, "Phone number must contain only numbers")
    .min(10)
    .max(10)
    .required(),
  addressline1: yup.string().required(),
  addressline2: yup.string(),
  city: yup.string().required(),
  zipcode: yup.string().min(6).max(6).required(),
  state: yup.string().required(),
});

function UpdateAddress(props) {
  const dispatch = useDispatch();
  const queryParameters = new URLSearchParams(window.location.search);
  const orderId = queryParameters.get("orderid");
  const {
    title,
    firstName,
    lastName,
    addressline1,
    addressline2,
    id,
    zipcode,
    alternateNumber,
    phone,
    email,
    billing,
    indexId,
    shippingAddress,
    userid, city, state, organisationName, gst
  } = props;
  const handleClose = () => {
    setShow(false);
  };
  // useEffect(() => {
  //   if (indexId === 0) {
  //     setShow(true);
  //   }
  // }, [indexId]);
  const [show, setShow] = useState(false);
  const handleShow = () => {
    setShow(true);
    dispatch(
      zipcodeCityState({
        zipcode: zipcode,
      })
    );
  };
  const { zipcodeCityStateData } = useSelector((state) => state.commonReducer);
  return (
    <>
      <Button variant="success" className="rounded" onClick={handleShow}>
        <i className="bi bi-pen-fill"></i>
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Formik
          enableReinitialize={id ? false : true}
          validationSchema={schema}
          onSubmit={async (values, { resetForm }) => {
            const apiResp = orderId ? await dispatch(orderAddressUpdate({...values, shippingAddress})) : await dispatch(updateUserAddress(values));
            if (apiResp?.payload?.status) {
              orderId ? dispatch(singleOrder({ id: orderId })) : dispatch(getUserAddressDetails());
              handleClose();
            }
            resetForm({ values: "" });
          }}
          initialValues={{
            firstName,
            lastName,
            alternateNumber,
            addressline1,
            addressline2: addressline2 === null ? "" : addressline2,
            city: id ? city : zipcodeCityStateData?.City,
            zipcode,
            state: id ? state : zipcodeCityStateData?.State,
            phone,
            email,
            index: indexId,
            id,
            userid,
            organisationName,
            gst
          }}
        >
          {({ handleSubmit, handleChange, values, setFieldValue, errors }) => (
            <Form className="container" onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Col md={6} sm={12}>
                  <Form.Group>
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="FirstName"
                      name="firstName"
                      value={values.firstName}
                      onChange={handleChange}
                      isInvalid={!!errors.firstName}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.firstName}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6} sm={12}>
                  <Form.Group>
                    <Form.Label>LastName</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="LastName"
                      name="lastName"
                      value={values.lastName}
                      onChange={handleChange}
                      isInvalid={!!errors.lastName}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.lastName}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={6} sm={12}>
                  <Form.Group>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      isInvalid={!!errors.email}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6} sm={12}>
                  <Form.Group>
                    <Form.Label>Phone</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Phone"
                      name="phone"
                      value={values.phone}
                      onChange={handleChange}
                      isInvalid={!!errors.phone}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.phone}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={6} sm={12}>
                  <Form.Group>
                    <Form.Label>Alternate Number</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Alternate Number"
                      name="alternateNumber"
                      value={values.alternateNumber}
                      onChange={handleChange}
                      isInvalid={!!errors.alternateNumber}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.alternateNumber}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6} sm={12}>
                  <Form.Group>
                    <Form.Label>AddressLine1</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="AddressLine1"
                      name="addressline1"
                      value={values.addressline1}
                      onChange={handleChange}
                      isInvalid={!!errors.addressline1}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.addressline1}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={6} sm={12}>
                  <Form.Group>
                    <Form.Label>AddressLine2</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="AddressLine2"
                      name="addressline2"
                      value={values.addressline2}
                      onChange={handleChange}
                      isInvalid={!!errors.addressline2}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.addressline2}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6} sm={12}>
                  <Form.Group>
                    <Form.Label>PIN Code</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="PIN Code"
                      name="zipcode"
                      value={values.zipcode}
                      onChange={async (e) => {
                        setFieldValue("zipcode", e.target.value);
                        e.target.value?.length === 6 &&
                          dispatch(
                            zipcodeCityState({
                              zipcode: e.target.value,
                            })
                          );
                      }}
                      isInvalid={!!errors.zipcode}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.zipcode}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={6} sm={12}>
                  <Form.Group>
                    <Form.Label>City</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="City"
                      name="city"
                      value={values.city}
                      onChange={handleChange}
                      isInvalid={!!errors.city}
                      disabled = {id ? false : true}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.city}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6} sm={12}>
                  <Form.Group>
                    <Form.Label>State</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="State"
                      name="state"
                      value={values.state}
                      onChange={handleChange}
                      isInvalid={!!errors.state}
                      disabled = {id ? false : true}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.state}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              {billing && <Row className="mb-3">
                <Col md={6} sm={12}>
                  <Form.Group>
                    <Form.Label>GST</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="GST"
                      name="gst"
                      value={values.gst}
                      onChange={handleChange}
                      isInvalid={!!errors.gst}
                      disabled = {id ? false : true}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.gst}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6} sm={12}>
                  <Form.Group>
                    <Form.Label>Company Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="OrganisationName"
                      name="organisationName"
                      value={values.organisationName}
                      onChange={handleChange}
                      isInvalid={!!errors.organisationName}
                      disabled = {id ? false : true}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.organisationName}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>}
              <Modal.Footer>
                <Button variant="success" type="submit">
                  Submit
                </Button>
                <Button variant="danger" onClick={handleClose}>
                  Close
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
}

export default UpdateAddress;
