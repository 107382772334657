import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SideMenu from "../../Components/SideMenu";
import { deleteOrderPdfFiles, getOrderPdfFiles } from "../../reducers/commonReducer";
import { handleDownload, home } from "../../const";
import { Button, Col, Row } from "react-bootstrap";
import FilterList from "./FilterList";

const OrderFiles = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (localStorage.getItem("slug") === "admin" ? !localStorage.getItem("x-admin-token") : !localStorage.getItem("x-auth-token")) {
      navigate(home);
    }
    dispatch(getOrderPdfFiles());
  }, []);
//delete order pdf
const deleteRecord = () =>{
 const apiResp = dispatch(deleteOrderPdfFiles())
 if(apiResp?.payload?.status){
  dispatch(getOrderPdfFiles())
 }
}
  const { getOrderPdfFilesList } = useSelector((state) => state.commonReducer);
  return (
    <>
      <SideMenu />
      <div className="mt-extra content container-fluid">
        <FilterList/>
       {getOrderPdfFilesList?.files?.length ? <Row>
          <Col>
            <Button onClick={deleteRecord} variant="danger" className="float-right">Delete <i className="bi bi-trash3-fill"></i></Button>
          </Col>
        </Row> : null}
        {/* Group orders */}
        <Row>
          <Col md={12}>
            <h3 className="text-black">
              <b>Group Image Pdf</b>
            </h3>
          </Col>
        </Row>
        <Row className="mb-4">
          {getOrderPdfFilesList?.files?.length ? (
            getOrderPdfFilesList?.files?.map((orderFile, index) => (
              <Col md={3} key={index}>
                <Button
                  onClick={() => handleDownload(orderFile)}
                  className="w-100 theme-btn py-4 word-wrap-break mb-2"
                >
                  {orderFile.substring(orderFile.lastIndexOf("/") + 1)}
                </Button>
              </Col>
            ))
          ) : (
            <h3 className="text-danger text-align-center">No record found</h3>
          )}
        </Row>
        {/* Single Image Pdf */}
        <Row>
          <Col md={12}>
            <h3 className="text-black">
              <b>Single Image Pdf(For testing)</b>
            </h3>
          </Col>
        </Row>
        <Row className="mb-4">
          {getOrderPdfFilesList?.singleList?.length ? (
            getOrderPdfFilesList?.singleList?.map((orderFile, index) => (
              <Col md={3} key={index}>
                <Button
                  onClick={() => handleDownload(orderFile)}
                  className="w-100 theme-btn py-4 word-wrap-break mb-2"
                >
                  {orderFile.substring(orderFile.lastIndexOf("/") + 1)}
                </Button>
              </Col>
            ))
          ) : (
            <h3 className="text-danger text-align-center">No record found</h3>
          )}
        </Row>
        {/* Marge Order Pdf */}
        <Row>
          <Col md={12}>
            <h3 className="text-black">
              <b>Marge Order</b>
            </h3>
          </Col>
        </Row>
        <Row className="mb-4">
          {getOrderPdfFilesList?.margeOrder?.length ? (
            getOrderPdfFilesList?.margeOrder?.map((orderFile, index) => (
              <Col md={3} key={index}>
                <Button
                  onClick={() => handleDownload(orderFile)}
                  className="w-100 theme-btn py-4 word-wrap-break mb-2"
                >
                  {orderFile.substring(orderFile.lastIndexOf("/") + 1)}
                </Button>
              </Col>
            ))
          ) : (
            <h3 className="text-danger text-align-center">No record found</h3>
          )}
        </Row>
      </div>
    </>
  );
};

export default OrderFiles;
