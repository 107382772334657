import React from "react";
import Footer from "../Sub/Footer";
import webBanner from "../../images/webBanner.jpg";
import CommonHeader from "../Sub/CommonHeader";
import Banner from "../Sub/Banner";
import { Col, Row } from "react-bootstrap";
import GotoTop from "../../Components/GotoTop";
import { Helmet } from "react-helmet";
import {baseUrl } from "../../const";

const About = () => {
  let width = window.innerWidth;
  return (
    <>
     <Helmet>
        <title>Ranbazaar - About</title>
        <meta name="description" content="Ranbazaar Private Limited"/>
        <link rel="canonical" href={`${baseUrl}about`} />
        <meta property="og:title" content="Ranbazaar - About"/>
        <meta property="og:site_name" content="Ranbazaar" />
        <meta property="og:url" content={`${baseUrl}about`} />
        <meta property="og:description" content="Ranbazaar Private Limited"/>
        <meta property="og:type" content="website" />
        <meta property="og:image" content="/ranbazar.png"/>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@ranbazaar" />
        <meta name="twitter:creator" content="@ranbazaar" />
        <meta name="twitter:title" content="Ranbazaar - About" />
        <meta property="twitter:description" content="Ranbazaar Private Limited" />
        <meta name="twitter:image" content="/ranbazar.png" />
      </Helmet>
      <CommonHeader />
      <div>
        <Banner bannerImg={webBanner} />
        </div>
        <main id="MainContent" className="content-for-layout focus-none" role="main" tabindex="-1">
  <div className="normal_main_content page-width ">
    <h1 className="page-title align-center">About Us</h1>
    
    <section id="shopify-section-template--22051474211108__main" className="shopify-section spaced-section">
      <div className="page-width">
        <div className="rte">
          <p><strong>Welcome to RanBazaar – Khushiyon Ka Bazaar!</strong></p>
          <p>Your Premier Destination for Customized Kids' Products!</p>
          
          <p>
            Launched in September 2023, RanBazaar is your go-to destination for personalized joy. 
            We specialize in customizing products designed to brighten every child’s day and add a unique touch to their everyday essentials.
            From personalized name slips and notebooks to custom pencils and exam boards, our range is all about making school and playtime special.
          </p>
          
          <p>
            At RanBazaar, we believe that every child deserves to stand out and feel special. 
            Our mission is to celebrate individuality by providing high-quality, customized products that reflect each child's personality and preferences.
            We understand that little details make a big difference, and our carefully crafted items are designed to spark excitement and pride.
          </p>
          
          <p>
            Whether you're looking for a special gift or just a fun way to make learning more exciting, RanBazaar has you covered with high-quality, custom-designed products perfect for school, home, or any occasion.
          </p>

          <p><strong>Why Choose RanBazaar?</strong></p>
          
          <ul>
            <li><strong>Innovative Customization:</strong> Our growing collection offers a wide variety of customizable options, allowing you to add a personal touch to your child’s favorite items.</li>
            <li><strong>Rapid Expansion:</strong> We continuously update our inventory with exciting products to keep up with the latest trends and customer preferences.</li>
            <li><strong>Quality & Reliability:</strong> Every product is meticulously designed to meet the highest standards of quality, ensuring satisfaction and durability.</li>
            <li><strong>Effortless Shopping:</strong> Our user-friendly website makes it easy to explore, customize, and order your desired products with just a few clicks.</li>
            <li><strong>Exceptional Service:</strong> Our dedicated customer support team is here to assist you, providing a smooth and enjoyable shopping experience.</li>
          </ul>
          
          <p>
            Thank you for choosing RanBazaar as your trusted source for customized kids' products. 
            We look forward to continuing to bring you more delightful and personalized options for your little ones!
          </p>
        </div>
      </div>
    </section>
  </div>
</main>



<GotoTop />
      <Footer />
    </>
  );
};

export default About;
