import React, { useEffect, useRef } from "react";
import { Row, Col, Table, Container, Button } from "react-bootstrap";
import printer from "../../images/printer.png";
import { useDispatch, useSelector } from "react-redux";
import { singleOrder } from "../../reducers/orderDetailsReducer";
import { Link } from "react-router-dom";
import { handleContextMenu, product } from "../../const";
import OrderImgPopUp from "../../ComponentsWeb/Main/OrderImgPopUp";

function OrderDetails(props) {
  const dispatch = useDispatch();
  const width = useRef(window.innerWidth);
  const queryParameters = new URLSearchParams(window.location.search);
  const orderId = queryParameters.get("orderid");
  useEffect(() => {
    orderId && dispatch(singleOrder({ id: orderId }));
  }, []);
  const { singleOrderData } = useSelector((state) => state.orderDetailReducer);
  return (
    <>
      <Container>
        <Row className="print-hide">
          <Col>
            <h2>Order Details</h2>
          </Col>
          <Col>
            <img
              className="w-auto f-right"
              onClick={() => window.print()}
              src={printer}
              onContextMenu={handleContextMenu}
            />
          </Col>
        </Row>
        <hr className="print-hide" />
        <Row>
          {!!singleOrderData?.shippingAddress && (
            <>
            <Col md={6}>
              <label className="cursor-auto">
                <div className={width.current > 668 ? "userAddress" : null}>
                  <h5>
                    <strong>Billing/Shipping Address</strong>
                  </h5>
                  <div>
                    <p className="mb-0">
                    Contact Person Name:{" "}
                      <strong>
                      {singleOrderData?.shippingAddress?.firstName +
                          " " +
                          singleOrderData?.shippingAddress?.lastName}
                      </strong>
                    </p>
                    <p className="mb-0">
                    Address:  <b>{singleOrderData?.shippingAddress?.addressline1 +
                          ", " +
                          (singleOrderData?.shippingAddress?.addressline2 || "") +
                          ", " +
                          singleOrderData?.shippingAddress?.city +
                          "-" +
                          singleOrderData?.shippingAddress?.zipcode +
                          ", " +
                          singleOrderData?.shippingAddress?.state}
                      </b>
                    </p>
                    <p className="mb-0">
                      <strong>Phone: </strong>
                      {singleOrderData?.shippingAddress?.phone}
                    </p>
                    <p className="mb-0">
                      <strong>Alternate Phone: </strong>
                      {singleOrderData?.shippingAddress?.alternateNumber}
                    </p>
                    <div className="d-none print-enable">
                      <h5 className="mt-3">
                        <strong>From</strong>
                      </h5>
                      <h6 className="mb-0">
                      <b>
                      Ranbazzar<br/>
                      FIRST FLOOR, SCO-104, POCKET A, SECTOR 14<br/>
                      Hisar, Haryana (125001), India
                      </b>
                      </h6>
                    </div>
                  </div>
                </div>
              </label>
            </Col>
            <Col md={6} className="mt-4">
                <div className={width.current > 668 ? "userAddress" : null}>
                  <div className={width.current > 500 ? "float-end" : null}>
                    <p className="mb-0 print-hide">
                      <strong>Customer Id: </strong>
                      <b> {singleOrderData?.user?.username}</b>
                    </p>
                    <p className="mb-0 print-hide">
                      <strong>OrderID: </strong>
                      <b> {singleOrderData?.successid ? singleOrderData?.successid : singleOrderData?.orderid}</b>
                    </p>
                    <p className="mb-0 print-hide">
                      <strong>Email: </strong>
                      <b> {singleOrderData?.user?.email}</b>
                    </p>
                    <p className="mb-0 print-hide">
                      <strong>Payment Transfer ID: </strong>
                      <b> {singleOrderData?.payment_trns_id}</b>
                    </p>
                    <p className="mb-0 print-hide">
                      <strong>Delivery Status: </strong>
                      <b> {singleOrderData?.deliveryStatus?.status}</b>
                    </p>
                    <p className="mb-0 print-hide">
                      <strong>WayBillNo: </strong>
                      <b> {singleOrderData?.waybillno}</b>
                    </p>
                    {/* <p className="mb-0 print-hide">
                      <strong>Tracking URL: </strong>
                      <b> {singleOrderData?.trackingid?.url}</b> | <strong>Tracking ID: </strong>
                      <b> {singleOrderData?.trackingNumber}</b> | <strong>Courier Company: </strong>
                      <b> {singleOrderData?.trackingid?.name}</b>
                    </p> */}
                  </div>
                </div>
            </Col>
            </>
          )}
        </Row>
          {!!singleOrderData?.items && (
           <>
             <Row className="mt-4">
              <Col>
              <div className="no-more-tables">
                <table className="w-100">
                  <thead>
                    <tr>
                      <th>SrNo.</th>
                      <th></th>
                      <th>ProductName</th>
                      <th>Quantity</th>
                      <th>Price</th>
                      <th>Total Price</th>
                      <th>View Product</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!!singleOrderData?.items &&
                      singleOrderData?.items.map((orders, index) => {
                        return (
                          <tr key={index}>
                            <td data-title="SrNo.">{index + 1}.</td>
                            <td data-title="Image">
                              <img
                                className="w-100px"
                                src={orders?.mergeimg ? orders?.mergeimg : orders?.productId?.images[0]}
                                onContextMenu={handleContextMenu}
                              />
                            </td>
                            <td data-title="ProductName"><b>
                              <Link to={`${product}/${orders?.productId?.slug}`} aria-label="Product Attribute">{orders?.productId?.name?.slice(0, 20) + "......"}</Link><br/>
                              <b className="text-success">{orders?.attribute?.name}</b>
                              </b>
                            </td>
                            <td data-title="Quantity">{orders?.quantity}</td>
                            <td data-title="Price">
                              {orders?.priceOfCustomize > 0
                                ? orders?.priceOfCustomize
                                : orders?.sale
                                ? orders?.sale
                                : orders?.mrp}/-
                            </td>
                            <td data-title="Total Price">
                              {orders?.quantity * ( orders?.sale ? orders?.sale : orders?.mrp)}/-
                            </td>
                            <td data-title="">
                              <OrderImgPopUp title={"Check You Product"} image={orders?.mergeimg} btn={true}/>
                              {((singleOrderData?.status === 0) || (singleOrderData?.status === 2)) && <Link to={`${product}/${orders?.productId?.slug}?orderid=${singleOrderData?.id}&itemIndex=${index}&productType=true`} aria-label="product" className="m-1">
                                <Button className="theme-btn px-2 py-1">Update</Button>
                              </Link>}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
              </Col>
            </Row>
            <Row className="border-bottom">
              <Col md={8} sm={8} xs={8}>
                <p className="mb-0 f-16"><b>Total Quantity</b></p>
              </Col>
              <Col md={4} sm={4} xs={4}>
                <p className="mb-0 f-16 float-right">{singleOrderData?.totalquantity}</p>
              </Col>
            </Row>
            {!!singleOrderData?.adminDiscount?.totalDiscount && <Row className="border-bottom">
              <Col md={8} sm={8} xs={8}>
                <p className="mb-0 f-16"><b>{singleOrderData?.adminDiscount?.comment}</b></p>
              </Col>
              <Col md={4} sm={4} xs={4}>
                <p className="mb-0 f-16 float-right">{singleOrderData?.adminDiscount?.totalDiscount?.toFixed(0)}/-</p>
              </Col>
            </Row>}
            {!!singleOrderData?.codChargesAmount && <Row className="border-bottom">
              <Col md={8} sm={8} xs={8}>
                <p className="mb-0 f-16"><b>COD Charges</b></p>
              </Col>
              <Col md={4} sm={4} xs={4}>
                <p className="mb-0 f-16 float-right">{singleOrderData?.codChargesAmount?.toFixed(0)}/-</p>
              </Col>
            </Row>}
            {!!singleOrderData?.shippingCost && <Row className="border-bottom">
              <Col md={8} sm={8} xs={8}>
                <p className="mb-0 f-16"><b>Shipping Charges</b></p>
              </Col>
              <Col md={4} sm={4} xs={4}>
                <p className="mb-0 f-16 float-right">{singleOrderData?.shippingCost}/-</p>
              </Col>
            </Row>}
            {!!singleOrderData?.coupondiscount && <Row className="border-bottom">
              <Col md={4} sm={4} xs={4}>
                <p className="mb-0 f-16"><b>Coupon Discount</b></p>
              </Col>
              <Col md={4} sm={4} xs={4}>
                <p className="mb-0 f-16">{singleOrderData?.offer?.name}</p>
              </Col>
              <Col md={4} sm={4} xs={4}>
                <p className="mb-0 f-16 float-right">- {singleOrderData?.coupondiscount}/-</p>
              </Col>
            </Row>}
            {!!singleOrderData?.qtydiscount && <Row className="border-bottom">
              <Col md={4} sm={4} xs={4}>
                <p className="mb-0 f-16"><b>Qty Discount</b></p>
              </Col>
              <Col md={4} sm={4} xs={4}>
                <p className="mb-0 f-16">{singleOrderData?.offer?.name}</p>
              </Col>
              <Col md={4} sm={4} xs={4}>
                <p className="mb-0 f-16 float-right">- {singleOrderData?.qtydiscount}/-</p>
              </Col>
            </Row>}
            {!!singleOrderData?.totalPaybleAmount && <Row className="border-bottom">
              <Col md={8} sm={8} xs={8}>
                <p className="mb-0 f-16"><b>Order Total</b></p>
              </Col>
              {/* <Col md={4} sm={4} xs={4}>
                <p className="mb-0 f-16 float-right">  {singleOrderData?.userBalanceCharges > 0
                            ? singleOrderData?.payment.toFixed(0)
                            : singleOrderData?.totalPaybleAmount.toFixed(0)}/-</p>
              </Col> */}
               <Col md={4} sm={4} xs={4}>
                <p className="mb-0 f-16 float-right">  {(singleOrderData?.codChargesAmount + singleOrderData?.totalPaybleAmount + singleOrderData?.shippingCost)?.toFixed(0)}/-</p>
              </Col>
            </Row>}
           </>
          )}
          {/* {
            (localStorage.getItem("slug") == "admin" || localStorage.getItem("slug") == "superadmin") && <Row className="mt-5 print-hide">
            <Table bordered hover>
                <thead>
                  <tr>
                    <th>Status</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    !!singleOrderData?.statusTime && singleOrderData?.statusTime?.map((data, index) => {
                     
                      return(
                        <tr key={index}>
                          <td>{data?.status === 0 && <span className="text-primary"><b>Pending</b></span> || data?.status === 1 && <span className="text-danger"><b>Cancel</b></span> || data?.status === 2 && <span className="text-info"><b>Processing</b></span> ||
                          data?.status === 3 && <span className="text-warning"><b>Shipping</b></span> || data?.status === 4 && <span className="text-success"><b>Completed</b></span> || data?.status === 5 && <span className="text-dark"><b>Partially Delivered</b></span>}</td>
                          <td>{!!data?.time && moment(data?.time).format('MMMM Do YYYY, h:mm:ss a')}</td>
                        </tr>
                      )
                    })
                  }
                </tbody>
            </Table>
            </Row>
          } */}
      
      </Container>
    </>
  );
}

export default OrderDetails;
