import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SideMenu from "../../Components/SideMenu";
import {
  addProductLabel,
  deleteProductLabel,
  getProductLabel,
  updateProductLabel,
} from "../../reducers/commonReducer";
import { Button} from "react-bootstrap";
import { home } from "../../const";
import ProductLabelAction from "./ProductLabelAction";

const ProductLabelList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (localStorage.getItem("slug") === "admin" ? !localStorage.getItem("x-admin-token") : !localStorage.getItem("x-auth-token")) {
      navigate(home);
    }
    dispatch(getProductLabel());
  }, []);

  const { getProductLabelList } = useSelector((state) => state.commonReducer);
  return (
    <>
      <SideMenu />
      <div className="mt-extra content container-fluid">
        <ProductLabelAction
          title={"Add Label"}
          popUpTitle="Add New Tracker"
          api={addProductLabel}
        />
        <table className="table mt-2">
          <thead className="normalplace">
            <tr className="tables">
              <th className="over-col-size">Sr.</th>
              <th className="over-col-size">Name</th>
              <th className="over-col-size">Slug</th>
              <th className="over-col-size">Type</th>
              <th className="over-col-size">Status</th>
              <th className="over-col-size">Action</th>
            </tr>
          </thead>
          <tbody className="tbody-table">
            {!!getProductLabelList &&
              getProductLabelList?.map((data, index) => {
                return (
                  <React.Fragment key={index}>
                    <tr key={data?._id}>
                      <td>{index + 1}.</td>
                      <td>{data?.name}</td>
                      <td>{data?.slug}</td>
                      <td>{data?.type}</td>
                      <td>{data?.status ? <span className="text-success"><b>Active</b></span> : <span className="text-danger"><b>InActive</b></span>}</td>
                      <td>
                        <ProductLabelAction
                          title={<i className="bi bi-pencil-square"></i>}
                          popUpTitle="Update Label"
                          api={updateProductLabel}
                          id={data?.id}
                          name={data?.name}
                          type={data?.type}
                          status={data?.status}
                        />
                        <Button
                          variant="danger"
                          className="mx-1"
                          onClick={async () => {
                            const apirsp = await dispatch(deleteProductLabel(data?.id));
                            if(apirsp?.payload?.status){
                               dispatch(getProductLabel());
                            }
                          }}
                        >
                          <i className="bi bi-trash3-fill"></i>
                        </Button>
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ProductLabelList;
