import React, { useRef, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import {
  updateOrderStatus,
  updatePaymentOrderStatus,
  resetAdminOrderList,
  updateOrderItem,
} from "../../reducers/orderDetailsReducer";
import { Formik } from "formik";

const schema = yup.object().shape({
  refund: yup.string().required(),
  refcomment: yup.string().required(),
});
function RefundOrderAmount(props) {
  const dispatch = useDispatch();
  const width = useRef(window.innerWidth);
  const { title, orderId, listingApi } = props;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button
        className={width.current < 668 ? "px-1 py-0 mx-1" : "m-1"}
        variant="warning"
        onClick={handleShow}
      >
        <i className="bi bi-cash-stack"></i>
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          onSubmit={async (values, { resetForm }) => {
            const apiResp = await dispatch(updateOrderItem(values));
            if (apiResp?.payload?.status) {
              resetForm({ values: "" });
              handleClose();
              await dispatch(resetAdminOrderList());
              await dispatch(listingApi());
            }
          }}
          initialValues={{
            id: orderId,
            refund: "",
            refcomment: "",
          }}
        >
          {({
            handleSubmit,
            handleChange,
            touched,
            setFieldValue,
            values,
            errors,
          }) => (
            <Form className="container" onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Col md={12} sm={12}>
                  <Form.Group>
                    <Form.Label>Refund Amount</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Refund Amount"
                      name="refund"
                      value={values.refund}
                      onChange={async (e) => {
                        setFieldValue("refund", +e.target.value);
                      }}
                      isInvalid={!!errors.refund}
                      className={errors.refund && touched.refund && "errors"}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.refund}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={12} sm={12}>
                  <Form.Group>
                    <Form.Label>Refund comment.</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="Refcomment......."
                      name="refcomment"
                      value={values.refcomment}
                      onChange={handleChange}
                      isInvalid={!!errors.refcomment}
                      className={errors.refcomment && "errors"}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.refcomment}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Modal.Footer>
                <Button
                  variant="success"
                  className="bg-thememain"
                  type="submit"
                >
                  Submit
                </Button>
                <Button variant="danger" onClick={handleClose}>
                  Close
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
}

export default RefundOrderAmount;
