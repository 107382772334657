import React from "react";
import SideMenu from "../../Components/SideMenu";
import AdminOrderDetail from "./AdminOrderDetail";
import GotoTop from "../../Components/GotoTop";
import CreateOrderFromAdmin from "./CreateOrderFromAdmin";

const UserAdminOrderDetails = () => {
  const queryParameters = new URLSearchParams(window.location.search);
  const createByAdmin = queryParameters.get("createByAdmin");
  return (
    <>
      <SideMenu />
        <div className="mt-extra content container-fluid main-print-conatiner">
         {createByAdmin === "true" ? <CreateOrderFromAdmin/> : <AdminOrderDetail/> }
        </div>
        <GotoTop/>
    </>
  );
};

export default UserAdminOrderDetails;
