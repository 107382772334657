import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { capitalizeText } from "../../const";

export default function PrintImage({
  updateOrderImg,
  orderid,
  newWidth,
  newHeight,
  customBg,
  imageDimensions,
  customerImg,
  imageTopBottoms,
  imageLeftRights,
  rotations,
  imageScales,
  inputFields,
  setBodyContent
}) {
  const [bgImage, setBgImage] = useState(null);
  const xScale = imageDimensions?.width / newWidth;
  const yScale = imageDimensions?.height / newHeight;

  const { getSingleProductData } = useSelector(
    (state) => state.commonReducer
  );
  useEffect(() => {
    const image = new Image();
   image.src = `${getSingleProductData?.customizeDetails?.[
      getSingleProductData?.defaultSample
    ]?.productImg}`;
    image.crossOrigin = "Anonymous";
    image.onload = () => {
      setBgImage(image.src);
    };
    if(getSingleProductData?.productType === "normal"){
      localStorage.setItem("productImg", getSingleProductData?.images?.[0])
    }else{
      localStorage.setItem("productImg", getSingleProductData?.customizeDetails?.[
        getSingleProductData?.defaultSample
      ]?.productImg)
    }
  }, [ orderid]);
  // getSingleProductData?.customizeDetails?.[
  //   getSingleProductData?.defaultSample
  // ]?.productImg,
  const generateHtml = () => {
    // Start with the container div
    let htmlString = `<div
      style="
        background-color: ${customBg};
        width: ${imageDimensions?.width ? `${imageDimensions?.width?.toFixed(0)}px` : "auto"};
        height: ${imageDimensions?.height ? `${imageDimensions?.height?.toFixed(0)}px` : "auto"};
        position: relative;
        overflow: hidden;"
    >`;
  
    // Loop through each customer image and add the corresponding div and img tag
    if (customerImg && customerImg?.length > 0) {
      customerImg.forEach((img, index) => {
        let imageSrc = orderid
        ? updateOrderImg[index]?.imageUrl
        : localStorage.getItem(img.name) || `${img?.imageUrl}`;
        let width = img?.width?.endsWith("px")
          ? parseInt(img?.width, 10)
          : img?.width;
        let height = img?.height?.endsWith("px")
          ? parseInt(img?.height, 10)
          : img?.height;
      const scaledWidth = width * xScale;
      const scaledHeight = height * yScale;
        
        htmlString += `<div
          class="overflow-hidden"
          style="
            position: absolute;
            user-select: auto;
            width: ${scaledWidth?.toFixed(0)}px;
            height: ${scaledHeight?.toFixed(0)}px;
            display: inline-block;
            cursor: move;
            z-index: 1;
            transform: translate(${(img.x * xScale)?.toFixed(0)}px, ${(img.y * yScale)?.toFixed(0)}px);
            box-sizing: border-box;
            flex-shrink: 0;
            overflow: hidden;
          "
        >
          <img
            src="${imageSrc}"
            alt="${img.alt || "Image"}"
            cross-origin="Anonymous"
            style="
              position: relative;
              top: ${(imageTopBottoms[index] * yScale)?.toFixed(0)}px;
              left: ${(imageLeftRights[index] * xScale)?.toFixed(0)}px;
              transform: rotate(${rotations[index] || 0}deg)
                                  scale(${imageScales[index] || 1});
              object-fit: contain;
              height: 100%;
              width: 100%;"
          />
        </div>`;
      });
    }
  
    // Add the background container for the input fields
    htmlString += `<div
      class="name-slip name-slip-bg product-bg-container"
      style="
        background-image: url(${localStorage.getItem("productImg") || 'none'});
        width: ${imageDimensions?.width ? `${imageDimensions?.width?.toFixed(0)}px` : "auto"};
        height: ${imageDimensions?.height ? `${imageDimensions?.height?.toFixed(0)}px` : "auto"};
        background-size: cover;
        background-repeat: no-repeat;
        overflow: hidden;
        position: relative;
        z-index: 1;
      "
    >`;
  
    if (getSingleProductData?.customizeDetails?.[getSingleProductData?.defaultSample]?.inputFields && getSingleProductData?.customizeDetails?.[getSingleProductData?.defaultSample]?.inputFields?.length > 0) {
      getSingleProductData?.customizeDetails?.[getSingleProductData?.defaultSample]?.inputFields.forEach((field, index) => {
        let fieldValue = (orderid ? inputFields?.[index]?.value : localStorage.getItem(field?.label?.slug) || field.value);
        if (fieldValue?.length > +field?.maxLength) {
          let trimmedValue = fieldValue.substring(0, +field?.maxLength);
          if (fieldValue[+field?.maxLength] !== ' ' && trimmedValue.lastIndexOf(' ') !== -1) {
            trimmedValue = trimmedValue.substring(0, trimmedValue.lastIndexOf(' '));
          }
          fieldValue = trimmedValue;
        }
        const fontSize = parseFloat(field?.style?.fontSize);
        const scaledFontSize = fontSize * Math.min(xScale, yScale);
        const scaledInputWidth = field?.width * xScale;
        //font size according to width
        // const adjustFontSize = (fieldValue, maxWidth, initialFontSize) => {
        //   let adjustedFontSize = initialFontSize;
        //   const span = document.createElement('span');
        //   span.style.fontSize = `${adjustedFontSize}px`;
        //   span.style.visibility = 'hidden';
        //   span.style.whiteSpace = 'nowrap';
        //   document.body.appendChild(span);
        //   span.innerHTML = fieldValue;
        //   while (span.offsetWidth > +maxWidth && adjustedFontSize > 10) {
        //     adjustedFontSize -= 1;
        //     span.style.fontSize = `${adjustedFontSize}px`;
        //   }
        //   document.body.removeChild(span);
        //   return adjustedFontSize;
        // };
        // const adjustedFontSize = adjustFontSize(fieldValue, field?.width, scaledFontSize); 
        let formattedName;
        if(field?.style?.textType === "capitalize"){
          formattedName = capitalizeText(fieldValue);
        }
        htmlString += `<div
          class="input react-draggable react-draggable-dragged"
          style="
            position: absolute;
            user-select: auto;
            color: ${field.style?.color || "#000"};
            font-family: ${field.style?.fontFamily || "Arial"};
            font-weight: ${field.style?.fontWeight || "normal"};
            text-align: ${field.style?.textAlignment || "left"};
            transform: rotate(${field.style?.rotation || 0}deg);
            text-transform: ${field?.style?.textType};
            font-size:  ${scaledFontSize?.toFixed(0)}px;
            width: ${scaledInputWidth?.toFixed(0)}px;
            display: inline-block;
            top: ${(field.y * yScale)?.toFixed(0)}px;
            left: ${(field.x * xScale)?.toFixed(0)}px;
            cursor: move;
            max-width: 9.0072e+15px;
            max-height: 9.0072e+15px;
            box-sizing: border-box;
            flex-shrink: 0;
            overflow: hidden;
            z-index: 3;
          "
        >
          ${formattedName ? formattedName?.substring(0, +field?.maxLength) : fieldValue?.substring(0, +field?.maxLength)}
        </div>`;
      });
    }
  
    htmlString += `</div></div>`;
    setBodyContent(htmlString);
    return htmlString;
  };
  
  // Example usage
  const htmlString = generateHtml();
  return (
    <>
     {/* <div dangerouslySetInnerHTML={{ __html: htmlString }}></div>  */}
    </>
  );
}

