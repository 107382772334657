import React from "react";
import moment from "moment";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { adminOrderCommentDelete, singleOrder } from "../../reducers/orderDetailsReducer";

function Timeline({ comment, logs, user, shiplog }) {
  const queryParameters = new URLSearchParams(window.location.search);
  const orderId = queryParameters.get("orderid");
  const dispatch = useDispatch();
  const { loginUserID } = useSelector((state) => state.orderDetailReducer);
  return (
    <div className="bgtimeline">
      <div className="timeline">
        {/* order comment */}
        {!!comment &&
          comment?.map((data, index) => {
            return (
                <div key={index} className="timeline-entry2 stylish-entry">
                <div className="timeline-icon stylish-icon">
                  {data?.user?.firstName?.charAt(0) || ''}
                </div>
                <div className="timeline-content stylish-content">
                  <h5 className="user-name">{`${data?.user?.firstName} ${data?.user?.lastName || ''}`} {loginUserID === data?.user?.id ? <i className="bi bi-trash3-fill button-pointer f-right"
                  onClick={async()=>{
                    const confirsmResp = window.confirm("Are You sure delete this comment?")
                    if(confirsmResp){
                     const apiResp = await dispatch(adminOrderCommentDelete({
                      id : orderId,
                      commentid : data?._id
                     }))
                     if(apiResp?.payload?.status){
                       dispatch(singleOrder({
                        id: orderId 
                       }))
                     }
                    }
                  }}></i> : null}</h5>
                  <div className="comment-title">
                    {data?.comment}
                  </div>
                  <div className="recipient email">
                    {data?.user?.email}
                  </div>
                  <div className="recipient time-stamp">
                    {moment(data?.time).format("D MMM [at] h:mm a")}
                  </div>
                </div>
              </div>
            );
          })}
        {/* order logs */}
        {!!logs &&
          logs?.map((logs, index) => {
            return (
              <div key={index} className="timeline-entry">
                <div className="timeline-icon"></div>
                <div className="timeline-content">
                  <div className="mb-2">
                    {moment(logs?.time).format("D MMM")}
                  </div>
                  <div className="title">
                    {logs?.comment} By{" "}
                    {`${logs?.user?.firstName} ${logs?.user?.lastName || ""}`}
                    {/* {`${user?.firstName} ${user?.lastName || ""}`} */}
                  </div>
                  {logs?.other && <div><img className="w-50" src={logs?.other} loading="lazy"/></div>}
                  <div className="recipient">
                    <b>({logs?.user?.email})</b>
                    {/* <b>({user?.email})</b> */}
                  </div>
                  <div className="recipient">
                    {moment(logs?.time).format("D MMM [at] h:mm a")}
                  </div>
                </div>
              </div>
            );
          })}
        {/* Shipping logs */}
        {(Array.isArray(shiplog?.remarks) && shiplog.remarks.length === 0) ||
        (typeof shiplog?.remarks === "string" && !shiplog.remarks.trim()) ? (
          <>
            <Row>
              <Col>
                <h5>Shipping Logs</h5>
              </Col>
            </Row>
            {!shiplog?.remarks?.trim() &&
              shiplog?.remarks?.map((ship, index) => {
                return (
                  <div key={index} className="timeline-entry">
                    <div className="timeline-icon"></div>
                    <div className="timeline-content">
                      <div className="title">{ship}</div>
                    </div>
                  </div>
                );
              })}
          </>
        ) : null}
      </div>
    </div>
  );
}

export default Timeline;
