import React, { Fragment, useEffect, useState } from "react";
import {
  Row,
  Form,
  Col,
  Container,
  Button,
  Card,
} from "react-bootstrap";
import printer from "../../images/printer.png";
import { useDispatch, useSelector } from "react-redux";
import {adminOrders,orderCreateAdmin,singleOrder} from "../../reducers/orderDetailsReducer";
import { Link, useNavigate } from "react-router-dom";
import {
  adminUserAddressUrl,
  ordersUrl,
  pendingOrdersUrl,
} from "../../const";
import UpdateAddress from "../../ComponentsWeb/Main/user/UpdateAddress";

import {
  addUser,
  allProductListCatWise,
  getAllStates,
  getRoles,
  getUsers,
  resetUsersList,
  userProdfile,
} from "../../reducers/commonReducer";
import ProductSelection from "./ProductSelection";
import UserAction from "../User/UserAction";

function CreateOrderFromAdmin(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [copied, setCopied] = useState(false);
  const [userProfile, setUserProfile] = useState(false);
  const queryParameters = new URLSearchParams(window.location.search);
  const orderId = queryParameters.get("orderid");
  const pendingOrder = queryParameters.get("pending");
  const createByAdmin = queryParameters.get("createByAdmin");
  useEffect(() => {
    if (orderId) {
      dispatch(singleOrder({ id: orderId }));
    }
    dispatch(allProductListCatWise())
    dispatch(getRoles())
    dispatch(getAllStates())
  }, [orderId, dispatch]);
  const { singleOrderData } = useSelector(
    (state) => state.orderDetailReducer
  );
  const { getUsersData, userProdfileData } = useSelector(
    (state) => state.commonReducer
  );
  // useEffect(() => {
  //   if(userProdfileData){
  //     setUserProfile(false)
  //   }
  // },[userProdfileData])
  // 0 pending,1 cancel, 2 processing, 3 verify 4 In_Transit , 5 shipping, 6 completed
  let orderStatus;
  if (singleOrderData?.status === 0) {
    orderStatus = "Pending";
  } else if (singleOrderData?.status === 1) {
    orderStatus = "Cancel";
  } else if (singleOrderData?.status === 2) {
    orderStatus = "Processing";
  } else if (singleOrderData?.status === 3) {
    orderStatus = "Verify";
  } else if (singleOrderData?.status === 4) {
    orderStatus = "In_Transit";
  } else if (singleOrderData?.status === 5) {
    orderStatus = "Shipping";
  } else if (singleOrderData?.status === 6) {
    orderStatus = "Completed";
  } else {
    orderStatus = null;
  }
  const handleCopy = () => {
    const textToCopy = singleOrderData?.successid
      ? singleOrderData.successid
      : singleOrderData.orderid;
    navigator.clipboard.writeText(textToCopy).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 300); // Reset after animation
    });
  };
  return (
    <>
      <Container className="mb-1">
        <Row>
          <Col>
            <Row>
              <Col md={6}>
                {!createByAdmin && (
                  <div className="d-flex">
                    <Link
                      to={pendingOrder === "true" ? pendingOrdersUrl : ordersUrl}
                      onClick={() => dispatch(adminOrders())}
                    >
                      <h5>
                        <i className="bi bi-arrow-left"></i>{" "}
                        <b className="text-black">
                          {singleOrderData?.successid
                            ? singleOrderData?.successid
                            : singleOrderData?.orderid}
                        </b>
                      </h5>
                    </Link>
                    <i
                      onClick={handleCopy}
                      className={`button-pointer mt-1 mx-2 bi bi-clipboard ${
                        copied ? "copy-zoom" : ""
                      }`}
                    ></i>
                  </div>
                )}
              </Col>
              {localStorage.getItem("slug") !== "checker" ? (
                <Col md={6}>
                  {/* <Button variant="warning" className="f-right mx-3">
                    <i className="bi bi-clipboard"></i> Duplicate
                  </Button> */}
                  <img
                    className="w-auto f-right"
                    onClick={() => window.print()}
                    src={printer}
                  />
                </Col>
              ) : null}
            </Row>
          </Col>
        </Row>
        <Row>
          <Col md={8}>
            {
              createByAdmin === "true" && <ProductSelection api={orderCreateAdmin} userid={userProdfileData?.id} title="Select Product"/>
            }
          </Col>
          {localStorage.getItem("slug") === "admin" ? (
            <Col md={4}>
              {/* customer Details */}
              <div className="border rounded p-2 mb-2">
                {createByAdmin === "true" && (
                  <>
                    <Row className="mt-2 pb-3">
                      <Col md={12}>
                       <div className="d-flex justify-content-between">
                        <h5 className="text-black">Customer</h5>
                        <UserAction title="Add User" api={addUser} btn={"success"}/>
                       </div>
                        <Form.Control
                          type="number"
                          max={10}
                          placeholder="Search By Number..."
                          onChange={(e) => {
                            if (e.target.value?.length > 4) {
                              dispatch(resetUsersList());
                              dispatch(getUsers({ search: e.target.value }));
                            }
                          }}
                        />
                        {(!userProfile) &&
                          getUsersData?.map((user, index) => {
                            return (
                              <Fragment key={index}>
                                <Card
                                  className="p-2 rounded button-pointer"
                                  onClick={() =>{
                                    dispatch(
                                      userProdfile({
                                        phone: +user?.phone,
                                      })
                                    )
                                    setUserProfile(true)
                                  }
                                  }
                                >
                                  <p className="mb-0">
                                    <b>
                                      {user?.firstName} {user?.lastName || ""}
                                    </b>
                                  </p>
                                  <p className="mb-0">{user?.phone}</p>
                                  <p className="mb-0">{user?.email}</p>
                                </Card>
                              </Fragment>
                            );
                          })}
                      </Col>
                    </Row>
                  </>
                )}
                {singleOrderData?.otherWayBills?.length ? (
                  singleOrderData?.otherWayBills?.map((bill, billIndex) => {
                    return (
                      <Row key={billIndex}>
                        <Col md={5}>
                          <b className="text-success">{bill?.id}</b>
                        </Col>
                        <Col md={4}>
                          <b>{bill?.waybillno}</b>
                        </Col>
                        <Col md={3}>
                          <b>{bill?.status?.Status}</b>
                        </Col>
                      </Row>
                    );
                  })
                ) : (
                  <Row>
                    <Col md={12}>{singleOrderData?.waybillno}</Col>
                  </Row>
                )}
              </div>
              {(!!userProdfileData && userProfile) && (
                <div className="border rounded p-2">
                  <div className="d-flex justify-content-between">
                  <h6 className="text-black mb-0">
                    <b>Customer</b>
                  </h6>
                  <Button className="f-right" onClick={() => setUserProfile(false)} variant="danger">
                      <i className="bi bi-trash3"></i>
                    </Button>
                  </div>
                  <p className="mb-0">
                    <strong>{`${
                      userProdfileData?.address?.[
                        userProdfileData?.defaultAddress
                      ]?.firstName
                    } ${
                      userProdfileData?.address?.[
                        userProdfileData?.defaultAddress
                      ]?.lastName || ""
                    }`}</strong>
                  </p>
                  {createByAdmin !== "true" && <div className="d-flex justify-content-between mt-2">
                    <Link
                      variant="warning"
                      to={() =>
                        navigate(
                          `${adminUserAddressUrl}?userid=${userProdfileData?.id}`
                        )
                      }
                    >
                      <p>
                        <b>
                          More Address <i className="bi bi-arrow-right"></i>
                        </b>
                      </p>
                    </Link>
                  </div>}
                  <h6 className="text-black">
                    <b>Contact information</b>
                  </h6>
                  <p className="mb-0">
                    <strong>
                      <i className="bi bi-envelope"></i>{" "}
                      {
                        userProdfileData?.address?.[
                          userProdfileData?.defaultAddress
                        ]?.email
                      }
                    </strong>
                  </p>
                  <p>
                    <strong>
                      <i className="bi bi-phone-fill"></i>{" "}
                      {
                        userProdfileData?.address?.[
                          userProdfileData?.defaultAddress
                        ]?.phone
                      }
                    </strong>
                  </p>
                  {userProdfileData?.address?.[userProdfileData?.defaultAddress]
                    ?.alternateNumber && (
                    <>
                      <p className="mb-0">
                        <b>Alternate Number</b>
                      </p>
                      <p>
                        <strong>
                          <i className="bi bi-phone-fill"></i>{" "}
                          {
                            userProdfileData?.address?.[
                              userProdfileData?.defaultAddress
                            ]?.alternateNumber
                          }
                        </strong>
                      </p>
                    </>
                  )}

                  <div className="d-flex justify-content-between">
                    <h6 className="text-black mb-0">
                      <b>Shipping Address</b>
                    </h6>
                    <UpdateAddress
                      title={"Update Address"}
                      indexId={userProdfileData?.defaultAddress}
                      firstName={userProdfileData?.address?.[
                        userProdfileData?.defaultAddress
                      ]?.firstName}
                      lastName={userProdfileData?.address?.[
                        userProdfileData?.defaultAddress
                      ]?.lastName}
                      addressline1={
                        userProdfileData?.address?.[
                        userProdfileData?.defaultAddress
                      ]?.addressline1
                      }
                      addressline2={
                        userProdfileData?.address?.[
                        userProdfileData?.defaultAddress
                      ]?.addressline2
                      }
                      city={userProdfileData?.address?.[
                        userProdfileData?.defaultAddress
                      ]?.city}
                      zipcode={userProdfileData?.address?.[
                        userProdfileData?.defaultAddress
                      ]?.zipcode}
                      state={userProdfileData?.address?.[
                        userProdfileData?.defaultAddress
                      ]?.state}
                      phone={userProdfileData?.address?.[
                        userProdfileData?.defaultAddress
                      ]?.phone}
                      alternateNumber={
                        userProdfileData?.address?.[
                        userProdfileData?.defaultAddress
                      ]?.alternateNumber
                      }
                      email={userProdfileData?.address?.[
                        userProdfileData?.defaultAddress
                      ]?.email}
                      shippingAddress={true}
                      id={userProdfileData?.address?.[
                        userProdfileData?.defaultAddress
                      ]?._id}
                      userid={userProdfileData?.id}
                    />
                  </div>
                  <p className="mb-0 text-black">
                    <b>{`${
                      userProdfileData?.address?.[
                        userProdfileData?.defaultAddress
                      ]?.firstName
                    } ${
                      userProdfileData?.address?.[
                        userProdfileData?.defaultAddress
                      ]?.lastName || ""
                    }`}</b>
                  </p>
                  <p className="mb-0 text-black">{`${
                    userProdfileData?.address?.[
                      userProdfileData?.defaultAddress
                    ]?.addressline1
                  } ${
                    userProdfileData?.address?.[
                      userProdfileData?.defaultAddress
                    ]?.addressline2 || ""
                  }`}</p>
                  <p className="mb-0 text-black">
                    {
                      userProdfileData?.address?.[
                        userProdfileData?.defaultAddress
                      ]?.city
                    }{" "}
                    -{" "}
                    {
                      userProdfileData?.address?.[
                        userProdfileData?.defaultAddress
                      ]?.zipcode
                    }
                  </p>
                  <p className="mb-0 text-black">
                    {
                      userProdfileData?.address?.[
                        userProdfileData?.defaultAddress
                      ]?.state
                    }
                  </p>
                  <Link
                    target="_blank"
                    to={`https://www.google.com/maps/search/${
                      userProdfileData?.address?.[
                        userProdfileData?.defaultAddress
                      ]?.addressline1
                    } ${
                      userProdfileData?.address?.[
                        userProdfileData?.defaultAddress
                      ]?.addressline2
                    }, ${
                      userProdfileData?.address?.[
                        userProdfileData?.defaultAddress
                      ]?.city
                    }, ${
                      userProdfileData?.address?.[
                        userProdfileData?.defaultAddress
                      ]?.zipcode
                    }, ${
                      userProdfileData?.address?.[
                        userProdfileData?.defaultAddress
                      ]?.state
                    }`}
                  >
                    {" "}
                    <i className="bi bi-geo-alt"></i> View Map
                  </Link>
                  {/* Billing address */}
                  <div className="d-flex justify-content-between mt-3">
                  <h6 className="text-black mb-0">
                    <b>Billing Address</b>
                  </h6>
                </div>
                <p className="mb-0 text-black">{`${
                  userProdfileData?.billingAddress?.firstName
                } ${userProdfileData?.billingAddress?.lastName || ""}`}</p>
                <p className="mb-0 text-black">
                  {userProdfileData?.billingAddress?.addressline1}
                </p>
                {userProdfileData?.billingAddress?.addressline2 && (
                  <p className="mb-0 text-black">
                    {userProdfileData?.billingAddress?.addressline2}
                  </p>
                )}
                <p className="mb-0 text-black">
                  {userProdfileData?.billingAddress?.city} -{" "}
                  {userProdfileData?.billingAddress?.zipcode}
                </p>
                <p className="mb-0 text-black">
                  {userProdfileData?.billingAddress?.state}
                </p>
                <p className="mb-0 text-black">
                  <i className="bi bi-phone-fill"></i>{" "}
                  {userProdfileData?.billingAddress?.phone}
                </p>
                <p className="text-black mb-0">
                  <i className="bi bi-envelope"></i>{" "}
                  {userProdfileData?.billingAddress?.email}
                </p>
                <p className="text-black mb-0">
                  Company: {userProdfileData?.billingAddress?.organisationName}
                </p>
                <p className="text-black mb-0">
                  GST: {userProdfileData?.billingAddress?.gst}
                </p>
                <Link
                  target="_blank"
                  to={`https://www.google.com/maps/search/${userProdfileData?.billingAddress?.addressline1} ${userProdfileData?.billingAddress?.addressline2}, ${userProdfileData?.billingAddress?.city}, ${userProdfileData?.billingAddress?.zipcode}, ${userProdfileData?.billingAddress?.state}`}
                >
                  {" "}
                  <i className="bi bi-geo-alt"></i> View Map
                </Link>
                </div>
              )}
            </Col>
          ) : null}
        </Row>
      </Container>
    </>
  );
}

export default CreateOrderFromAdmin;
