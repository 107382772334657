import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SideMenu from "../../Components/SideMenu";
import {
  addCod,
  deleteCod,
  getCod,
  updateCod,
} from "../../reducers/commonReducer";
import { Button, Form} from "react-bootstrap";
import { home } from "../../const";
import CodAction from "./CodAction";
import { getCategoriesFrontEnd } from "../../reducers/frontEndReducer";

const CodList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (localStorage.getItem("slug") === "admin" ? !localStorage.getItem("x-admin-token") : !localStorage.getItem("x-auth-token")) {
      navigate(home);
    }
    dispatch(getCod());
    dispatch(getCategoriesFrontEnd())
  }, []);

  const { getCodList } = useSelector((state) => state.commonReducer);
  return (
    <>
      <SideMenu />
      <div className="mt-extra content container-fluid">
        {/* <CodAction
          title={"Add New Rule"}
          popUpTitle="Add New Rule"
          api={addCod}
        /> */}
        <table className="table mt-2">
          <thead className="normalplace">
            <tr>
              <th className="over-col-size">Sr.</th>
              <th className="over-col-size">Name</th>
              <th className="over-col-size">Phone</th>
              <th className="over-col-size">Pin Code</th>
              <th className="over-col-size">Product SKU</th>
              <th className="over-col-size">Categories Slug</th>
              <th className="over-col-size">Status</th>
              <th></th>
              <th className="over-col-size">Action</th>
            </tr>
          </thead>
          <tbody>
            {!!getCodList &&
              getCodList?.map((data, index) => {
                return (
                  <React.Fragment key={index}>
                    <tr key={data?._id}>
                      <td>{index + 1}.</td>
                      <td>
                        {data?.name}<br/>
                        {data?.charges && <span><b>Charges:</b> {data?.charges}</span>}<br/>
                        {data?.percentage && <span><b>Percentage:</b> {data?.percentage ? "Yes" : "No"}</span>}
                      </td>
                      <td>{data?.phone?.map((item) => `${item}, `)}</td>
                      <td>{data?.pincode?.map((item) => `${item}, `)}</td>
                      <td>{data?.productsku?.map((item) => `${item}, `)}</td>
                      <td>{data?.categoriesSlug?.map((item) => `${item}, `)}</td>
                      <td>{data?.status ? <span className="text-success"><b>Active</b></span> : <span className="text-danger"><b>InActive</b></span>}</td>
                      <td>
                      <Form.Check
                          type="switch"
                          checked={data.status}
                          onChange={async (e) => {
                            const statusValue = e.target.checked;
                            const apiResp = await dispatch(updateCod({ id: data?.id, status: statusValue }));
                            if (apiResp?.payload?.status) {
                              dispatch(getCod());
                            }
                          }}
                        />
                      </td>
                      <td>
                        <CodAction
                          title={<i className="bi bi-pencil-square"></i>}
                          popUpTitle="Update COD Rule"
                          api={updateCod}
                          id={data?.id}
                          name={data?.name}
                          phone={data?.phone}
                          pincode={data?.pincode}
                          productsku={data?.productsku}
                          categoriesSlug={data?.categoriesSlug}
                          status={data?.status}
                          charges={data?.charges} percentage={data?.percentage}
                        />
                        {/* <Button
                          variant="danger"
                          className="mx-1"
                          onClick={async () => {
                            const apirsp = await dispatch(deleteCod({id:data?.id}));
                            if(apirsp?.payload?.status){
                               dispatch(getCod());
                            }
                          }}
                        >
                          <i className="bi bi-trash3-fill"></i>
                        </Button> */}
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default CodList;
