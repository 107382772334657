import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Formik } from "formik";
import * as yup from "yup";
import { Col, Form, Row } from "react-bootstrap";
import { singleOrder } from "../../reducers/orderDetailsReducer";
import { useDispatch } from "react-redux";

const schema = yup.object().shape({
  shippingCost: yup.string(),
  discount: yup.number(), // Assuming discount is a number
  discountType: yup.string().when("discount", {
    is: (discount) => discount !== undefined && discount !== 0, // Check if discount has a value
    then: (schema) =>
      schema.required("Discount type is required when discount is provided"),
    otherwise: (schema) => schema.notRequired(),
  }),
  comment: yup.string().when("discount", {
    is: (discount) => discount !== undefined && discount !== 0, // Check if discount has a value
    then: (schema) =>
      schema.required("Comment is required when discount is provided"),
    otherwise: (schema) => schema.notRequired(),
  }),
});

function OrderAmountUpdate({
  shippingCost,
  discountType,
  discount,
  comment,
  codChargesAmount,
  id, orderId, api
}) {
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Button variant="success" className="f-right" onClick={handleShow}>
        <i className="bi bi-pen-fill"></i>
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton className="bg-success text-white">
          <Modal.Title>Update</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            validationSchema={schema}
            onSubmit={async (values, { resetForm }) => {
              const apiResp = await dispatch(api(values));
              if(apiResp?.payload?.status){
                dispatch(singleOrder({ id: orderId }));
                resetForm({ values: "" });
                handleClose();
              }
            }}
            initialValues={{
              shippingCost,
              discountType,
              discount,
              comment,
              codChargesAmount,
              id,
            }}
          >
            {({ handleSubmit, handleChange, values, errors }) => (
              <Form className="container" onSubmit={handleSubmit}>
                <Row className="mb-3">
                  <Col md={6} sm={12}>
                    <Form.Group>
                      <Form.Label>
                        <b>Shipping Cost</b>
                      </Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Enter ShippingCost"
                        name="shippingCost"
                        value={values.shippingCost}
                        onChange={handleChange}
                        isInvalid={!!errors.shippingCost}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.shippingCost}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={6} sm={12}>
                    <Form.Group>
                      <Form.Label>
                        <b>Cod Charges Amount</b>
                      </Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Enter Cod Charges Amount"
                        name="codChargesAmount"
                        value={values.codChargesAmount}
                        onChange={handleChange}
                        isInvalid={!!errors.codChargesAmount}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.codChargesAmount}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-3">
                <Col md={12} sm={12}>
                    <Form.Group>
                      <Form.Label>
                        <b>Discount</b>
                      </Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Enter Discount"
                        name="discount"
                        value={values.discount}
                        onChange={handleChange}
                        isInvalid={!!errors.discount}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.discount}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={6}>
                    <Form.Label>Select Discount Type</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={handleChange}
                      name="discountType"
                      className="form-control"
                      value={values.discountType}
                      isInvalid={!!errors.discountType} // Show invalid style if there's an error
                    >
                      <option value="">Select Discount Type</option>
                      <option value="amount">Amount</option>
                      <option value="percentage">Percentage (%)</option>
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.discountType}
                    </Form.Control.Feedback>
                  </Col>

                  <Col md={6} sm={12}>
                    <Form.Group>
                      <Form.Label>
                        <b>Discount Comment</b>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Comment"
                        name="comment"
                        value={values.comment}
                        onChange={handleChange}
                        isInvalid={!!errors.comment}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.comment}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Modal.Footer>
                  <Button variant="success" type="submit">
                    Submit
                  </Button>
                  <Button variant="danger" onClick={handleClose}>
                    Close
                  </Button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default OrderAmountUpdate;
