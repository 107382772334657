import React, {useEffect, useState, Fragment} from 'react';
import { useDispatch, useSelector } from "react-redux";
import { getORderResponse } from '../../reducers/commonReducer';
import { Button, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { shop } from '../../const';

const ThankYouPage = ({title, textColor, status, subTitle}) => {
    const dispatch = useDispatch();
    const queryParameters = new URLSearchParams(window.location.search);
    const order_id = queryParameters.get("order_id");
    useEffect(() => {
        dispatch(getORderResponse({
            orderid:order_id
          }))
    }, [])
    const { getORderResponseStatus } = useSelector(
        (state) => state.commonReducer
      );

    return (
        <div className='thanku'>
        <div className="container3">
            {/* Header and Thank You Message */}
            <div className="header3">
                <h1 className={textColor}>
                    {title ? title : "Server Error!"}
                </h1>
            </div>

            <div className="thank-you-message3">
                <p className='tname'>Order <strong>{getORderResponseStatus?.order?.successid}</strong> {subTitle}. Thank you, <strong>{getORderResponseStatus?.order?.billingAddress?.firstName} {getORderResponseStatus?.order?.billingAddress?.lastName || ''}</strong>!</p>
            </div>

            {/* Order Summary Section */}
            <div className="order-summary3">
                <h2>Order Summary</h2>
               {getORderResponseStatus?.order?.items?.map((item, index) => {
                return(
                    <div className="summary-item3" key={index}>
                    <div className="summary-details3">
                        <strong>{item?.productId?.name}</strong>
                       {item?.attribute?.name && <strong>{item?.attribute?.name}</strong>}<br/>
                        SKU: {item?.productId?.sku}<br />
                        {item?.inputFields?.reduce((uniqueInputs, currentInput) => {
                                  if (!uniqueInputs.some(input => input?.slug === currentInput?.slug)) {
                                    uniqueInputs.push(currentInput);
                                  }
                                  return uniqueInputs;
                                }, [])
                                .map((inputs, inputIndex) => {
                                  return (
                                    <Fragment key={inputIndex}>
                                      <p className="mb-0">
                                        <strong>-{inputs?.name}</strong>:{" "}
                                        <b className="text-black">{inputs?.value}</b>
                                      </p>
                                    </Fragment>
                                  );
                                })}
                        {/* {
                            item?.inputFields?.map((input, inputIndex) =>{
                                return(
                                    <React.Fragment key={inputIndex}>
                                     - {input?.label?.name}: {input?.value}<br />
                                    </React.Fragment>
                                )
                            })
                        } */}
                    </div>
                    <img src={item?.mergeimg} alt={item?.productId?.name} loading="lazy"/>
                </div>
                )
               })}
                <Row>
                    <Col md={4} sm={4} xs={4}>Subtotal:</Col>
                    <Col className='text-align-end' md={8} sm={8} xs={8}>₹ {getORderResponseStatus?.order?.totalPaybleAmount}</Col>
                </Row>
                {getORderResponseStatus?.order?.codChargesAmount > 0 &&<Row>
                    <Col md={4} sm={4} xs={4}>COD Charges:</Col>
                    <Col className='text-align-end' md={8} sm={8} xs={8}>₹ {getORderResponseStatus?.order?.codChargesAmount}</Col>
                </Row>}
                <Row className="total3">
                    <Col md={4} sm={4} xs={4}>Total:</Col>
                    <Col className='text-align-end' md={8} sm={8} xs={8}>₹ {getORderResponseStatus?.order?.totalPaybleAmount + getORderResponseStatus?.order?.codChargesAmount + getORderResponseStatus?.order?.shippingCost}</Col>
                </Row>
            </div>

            {/* Map Section */}
            <div className="map-container3">
                <iframe src="https://www.google.com/maps/embed?pb=..." allowFullScreen="" loading="lazy"></iframe>
                <div className="shipping-info3">
                    <strong>Shipping Address</strong>
                    <p>{getORderResponseStatus?.order?.shippingAddress?.firstName} {getORderResponseStatus?.order?.shippingAddress?.lastName || ''}<br />
                    {getORderResponseStatus?.order?.shippingAddress?.addressline1} {getORderResponseStatus?.order?.shippingAddress?.addressline2 || ''}<br />
                    {getORderResponseStatus?.order?.shippingAddress?.city} - {getORderResponseStatus?.order?.shippingAddress?.zipcode}, {getORderResponseStatus?.order?.shippingAddress?.state}, India</p>
                </div>
            </div>

            {/* Billing Address Section Below the Map */}
            <div className="billing-info3">
                <strong>Billing Address</strong>
                <p>{getORderResponseStatus?.order?.billingAddress?.firstName} {getORderResponseStatus?.order?.billingAddress?.lastName || ''}<br />
                    {getORderResponseStatus?.order?.billingAddress?.addressline1} {getORderResponseStatus?.order?.billingAddress?.addressline2 || ''}<br />
                    {getORderResponseStatus?.order?.billingAddress?.city} - {getORderResponseStatus?.order?.billingAddress?.zipcode}, {getORderResponseStatus?.order?.billingAddress?.state}, India</p>
            </div>
            {/* Contact Information */}
            <div className="card3">
                <i className="fas fa-envelope"></i>
                <div>
                    <strong>Contact Information</strong>
                    <p className='mb-0'>{getORderResponseStatus?.order?.billingAddress?.email}</p>
                    <p>{getORderResponseStatus?.order?.billingAddress?.phone}</p>
                </div>
            </div>
            <div className="card3">
                <i className="fas fa-money-bill-wave"></i>
                <div>
                    <strong>Payment Method</strong>
                    <p>{getORderResponseStatus?.order?.paymenttype} - ₹ {getORderResponseStatus?.order?.totalPaybleAmount + getORderResponseStatus?.order?.codChargesAmount}</p>
                </div>
            </div>
            <Row>
                <Col sm={12} xs={12} md={6} className='my-2'>
                    <Link to={`/user-dashboard?eventKeytab=second`} aria-label="Orders">
                        <Button className='theme-btn w-100'>My Order</Button>
                    </Link>
                </Col>
                <Col sm={12} xs={12} md={6} className='my-2'>
                    <Link to={shop} aria-label="Shop">
                        <Button className='theme-btn w-100'>Continue To Shopping</Button>
                    </Link>
                </Col>
                {(status === 0 || status === 1) && <Col sm={12} xs={12} md={6} className='my-2'>
                    <Link to={`/user-dashboard?eventKeytab=second`} aria-label="Re-Attempt">
                        <Button className='theme-btn w-100'>Re-Attempt</Button>
                    </Link>
                </Col>}
            </Row>
        </div>
        </div>
    );
};

export default ThankYouPage;
