import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Formik } from "formik";
import { Col, Form, Row } from 'react-bootstrap';
import { singleOrder } from '../../reducers/orderDetailsReducer';
import { useDispatch } from 'react-redux';

function AWBAction({title, awb, id, api}) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const dispatch = useDispatch();
  return (
    <>
      <Button variant="success" className='f-right px-2 py-0 m-1' onClick={handleShow}>
        AWB <i className="bi bi-pen-fill"></i>
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton className='bg-success text-white'>
          <Modal.Title>{title} AWB</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Formik
          onSubmit={async (values, { resetForm }) => {
            const apiResp = await dispatch(api(values));
            if(apiResp?.payload?.status){
              dispatch(singleOrder({ id }));
              resetForm({ values: "" });
              handleClose();
            }
          }}
          initialValues={{
            otherWayBills : awb ? awb?.map((item) => item?.waybillno) : '',
            id
          }}
        >
          {({ handleSubmit, handleChange, values, errors }) => (
            <Form className="container" onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Col md={12} sm={12}>
                  <Form.Group>
                    <Form.Label><b>AWB</b></Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter AWB number"
                      name="otherWayBills"
                      value={values.otherWayBills}
                      onChange={handleChange}
                      isInvalid={!!errors.otherWayBills}
                    />

                    <Form.Control.Feedback type="invalid">
                      {errors.otherWayBills }
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Modal.Footer>
                <Button variant="success" type="submit">
                  Submit
                </Button>
                <Button variant="danger" onClick={handleClose}>
                  Close
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AWBAction;