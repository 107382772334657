// import React, { useEffect } from "react";

// export default function SingleCustomizeProduct({
//   handleContextMenu,
//   customBg,
//   customizeWidth,
//   customizeHeight,
//   newWidth,
//   newHeight,
//   customerImg,
//   images,
//   bgCustomImage,
//   inputFieldsShowAndUpdated,
//   inputFields,
//   orderid,
//   imageTopBottoms,
//   imageLeftRights,
//   rotations,
//   imageScales,
//   containerImgRef,
//   capitalizeText,setIsVisible, isBgLoaded
// }) {
//       // Intersection Observer for visibility
//   useEffect(() => {
//     const observer = new IntersectionObserver(
//       ([entry]) => setIsVisible(entry.isIntersecting),
//       { threshold: 0.1 }
//     );
//     if (containerImgRef.current) observer.observe(containerImgRef.current);
//     return () => {
//       if (containerImgRef.current) observer.unobserve(containerImgRef.current);
//     };
//   }, []);
//   if (!isBgLoaded) return <div style={{
//     background: "#fff",
//     width: customizeWidth,
//     height: customizeHeight,
//   }} />;
//   // End Intersection Observer for visibility
//   return (
//     <>
//       <div
//         onContextMenu={handleContextMenu}
//         style={{
//           backgroundColor: `${customBg}`,
//           width: customizeWidth,
//           height: customizeHeight,
//           maxWidth: `${newWidth}px`,
//           maxHeight: `${newHeight}px`,
//           position: "relative",
//           overflow: "hidden",
//           margin: "auto",
//         }}
//       >
//         {/* Image behind the name-slip */}
//         {!!customerImg &&
//           customerImg?.map((img, index) => {
//             let imageSrc = orderid
//               ? images?.[index]?.imageUrl
//               : localStorage.getItem(img.name) || `${img?.imageUrl}`;
//             return (
//               <div
//                 key={index}
//                 onContextMenu={handleContextMenu}
//                 className="overflow-hidden"
//                 style={{
//                   position: "absolute",
//                   userSelect: "auto",
//                   width: img?.width?.endsWith("px")
//                     ? img.width
//                     : `${img.width}px`,
//                   height: img?.height?.endsWith("px")
//                     ? img.height
//                     : `${img.height}px`,
//                   display: "inline-block",
//                   cursor: "move",
//                   zIndex: 1,
//                   transform: `translate(${img.x}px, ${img.y}px)`,
//                   boxSizing: "border-box",
//                   flexShrink: 0,
//                   overflow: "hidden",
//                 }}
//               >
//                 <img
//                   onContextMenu={handleContextMenu}
//                   className="w-100"
//                   src={imageSrc}
//                   alt={images?.[index]?._id}
//                   style={{
//                     position: "relative",
//                     top: `${imageTopBottoms[index]}px`,
//                     left: `${imageLeftRights[index]}px`,
//                     transform: `
//                                   rotate(${rotations[index] || 0}deg) 
//                                   scale(${imageScales[index] || 1}) `,
//                     objectFit: "contain",
//                     height: "100%",
//                     width: "100%",
//                   }}
//                 />
//               </div>
//             );
//           })}
//         <div
//           ref={containerImgRef}
//           className="name-slip name-slip-bg product-bg-container"
//           style={{
//             background: `url(${bgCustomImage})`,
//             width: customizeWidth,
//             height: customizeHeight,
//             backgroundSize: `cover`,
//             backgroundRepeat: `no-repeat`,
//             maxWidth: `${newWidth}px`,
//             maxHeight: `${newHeight}px`,
//             backgroundSize: "contain",
//             overflow: "hidden",
//             position: "relative",
//             zIndex: 2,
//             transition: "background 0.2s ease-in-out",
//           }}
//         >
//           {!!inputFieldsShowAndUpdated &&
//             inputFieldsShowAndUpdated?.map((field, index) => {
//               const fontSize = parseFloat(field?.style?.fontSize);
//               const maxLength = +field?.maxLength;
//               let fieldValue = orderid
//                 ? inputFields?.[index]?.value
//                 : localStorage.getItem(field?.label?.slug) || field.value;
//               if (fieldValue?.length > maxLength) {
//                 fieldValue = fieldValue.substring(0, maxLength);
//                 if (
//                   fieldValue[maxLength - 1] !== " " &&
//                   fieldValue.lastIndexOf(" ") !== -1
//                 ) {
//                   fieldValue = fieldValue.substring(
//                     0,
//                     fieldValue.lastIndexOf(" ")
//                   );
//                 }
//               }
//               const formattedName = capitalizeText(fieldValue);
//               return (
//                 <div
//                   key={index}
//                   className="input react-draggable react-draggable-dragged"
//                   style={{
//                     position: "absolute",
//                     userSelect: "auto",
//                     color: field?.style?.color,
//                     fontFamily: field?.style?.fontFamily,
//                     fontWeight: field?.style?.fontWeight,
//                     textAlign: field?.style?.textAlignment,
//                     textTransform: field?.style?.textType
//                       ? field?.style?.textType
//                       : `inherit`,
//                     transform: `rotate(${field?.style?.rotation || 0}deg)`,
//                     fontSize: `${fontSize}px`,
//                     width: field?.width?.endsWith("px")
//                       ? field?.width
//                       : `${field?.width}px`,
//                     display: "inline-block",
//                     top: `${field?.y}px`,
//                     left: `${field?.x}px`,
//                     cursor: "move",
//                     maxWidth: "9.0072e+15px",
//                     maxHeight: "9.0072e+15px",
//                     boxSizing: "border-box",
//                     flexShrink: "0",
//                     overflow: "hidden",
//                     zIndex: 3,
//                   }}
//                 >
//                   {formattedName ? formattedName : fieldValue}
//                 </div>
//               );
//             })}
//         </div>
//       </div>
//     </>
//   );
// }
import React, { useEffect, useState } from "react";

export default function SingleCustomizeProduct({
  handleContextMenu,
  customBg,
  customizeWidth,
  customizeHeight,
  newWidth,
  newHeight,
  customerImg,
  images,
  bgCustomImage,
  inputFieldsShowAndUpdated,
  inputFields,
  orderid,
  imageTopBottoms,
  imageLeftRights,
  rotations,
  imageScales,
  containerImgRef,
  capitalizeText,
  setIsVisible,
}) {
  const [isBgLoaded, setIsBgLoaded] = useState(false);

  // Intersection Observer for visibility
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => setIsVisible(entry.isIntersecting),
      { threshold: 0.1 }
    );
    if (containerImgRef.current) observer.observe(containerImgRef.current);
    return () => {
      if (containerImgRef.current) observer.unobserve(containerImgRef.current);
    };
  }, [containerImgRef, setIsVisible]);

  // Background image loading logic
  useEffect(() => {
    const img = new Image();
    img.src = bgCustomImage;
    img.onload = () => setIsBgLoaded(true);
    img.onerror = () => console.error("Failed to load background image:", bgCustomImage);
  }, [bgCustomImage]);

  // Show loader until the background image is loaded
  if (!isBgLoaded) {
    return (
      <div
        style={{
          background: "#fff",
          width: customizeWidth,
          height: customizeHeight,
        }}
      />
    );
  }

  // Main render
  return (
    <div
      onContextMenu={handleContextMenu}
      style={{
        backgroundColor: `${customBg}`,
        width: customizeWidth,
        height: customizeHeight,
        maxWidth: `${newWidth}px`,
        maxHeight: `${newHeight}px`,
        position: "relative",
        overflow: "hidden",
        margin: "auto",
      }}
    >
      {/* Image behind the name-slip */}
      {!!customerImg &&
        customerImg.map((img, index) => {
          const imageSrc = orderid
            ? images?.[index]?.imageUrl
            : localStorage.getItem(img.name) || `${img?.imageUrl}`;
          return (
            <div
              key={index}
              onContextMenu={handleContextMenu}
              className="overflow-hidden"
              style={{
                position: "absolute",
                userSelect: "auto",
                width: img?.width?.endsWith("px")
                  ? img.width
                  : `${img.width}px`,
                height: img?.height?.endsWith("px")
                  ? img.height
                  : `${img.height}px`,
                display: "inline-block",
                cursor: "move",
                zIndex: 1,
                transform: `translate(${img.x}px, ${img.y}px)`,
                boxSizing: "border-box",
                flexShrink: 0,
                overflow: "hidden",
              }}
            >
              <img
                onContextMenu={handleContextMenu}
                className="w-100"
                src={imageSrc}
                alt={images?.[index]?._id}
                style={{
                  position: "relative",
                  top: `${imageTopBottoms[index]}px`,
                  left: `${imageLeftRights[index]}px`,
                  transform: `rotate(${rotations[index] || 0}deg) scale(${imageScales[index] || 1})`,
                  objectFit: "contain",
                  height: "100%",
                  width: "100%",
                }}
              />
            </div>
          );
        })}
      <div
        ref={containerImgRef}
        className="name-slip name-slip-bg product-bg-container"
        style={{
          background: `url(${bgCustomImage})`,
          width: customizeWidth,
          height: customizeHeight,
          backgroundSize: `cover`,
          backgroundRepeat: `no-repeat`,
          maxWidth: `${newWidth}px`,
          maxHeight: `${newHeight}px`,
          backgroundSize: "contain",
          overflow: "hidden",
          position: "relative",
          zIndex: 2,
          transition: "background 0.2s ease-in-out",
        }}
      >
        {!!inputFieldsShowAndUpdated &&
          inputFieldsShowAndUpdated.map((field, index) => {
            const fontSize = parseFloat(field?.style?.fontSize);
            const maxLength = +field?.maxLength;
            let fieldValue = orderid
              ? inputFields?.[index]?.value
              : localStorage.getItem(field?.label?.slug) || field.value;
            if (fieldValue?.length > maxLength) {
              fieldValue = fieldValue.substring(0, maxLength);
              if (
                fieldValue[maxLength - 1] !== " " &&
                fieldValue.lastIndexOf(" ") !== -1
              ) {
                fieldValue = fieldValue.substring(
                  0,
                  fieldValue.lastIndexOf(" ")
                );
              }
            }
            const formattedName = capitalizeText(fieldValue);
            return (
              <div
                key={index}
                className="input react-draggable react-draggable-dragged"
                style={{
                  position: "absolute",
                  userSelect: "auto",
                  color: field?.style?.color,
                  fontFamily: field?.style?.fontFamily,
                  fontWeight: field?.style?.fontWeight,
                  textAlign: field?.style?.textAlignment,
                  textTransform: field?.style?.textType || `inherit`,
                  transform: `rotate(${field?.style?.rotation || 0}deg)`,
                  fontSize: `${fontSize}px`,
                  width: field?.width?.endsWith("px")
                    ? field?.width
                    : `${field?.width}px`,
                  display: "inline-block",
                  top: `${field?.y}px`,
                  left: `${field?.x}px`,
                  cursor: "move",
                  boxSizing: "border-box",
                  flexShrink: 0,
                  overflow: "hidden",
                  zIndex: 3,
                }}
              >
                {formattedName || fieldValue}
              </div>
            );
          })}
      </div>
    </div>
  );
}
