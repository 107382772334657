import React from "react";
import { Button } from "react-bootstrap";
import { downloadExcel } from "react-export-table-to-excel";
import { useDispatch } from "react-redux";

const ExportData = (props) => {
  const dispatch = useDispatch();
  const { title, header, body, tableName, api, exportRecord, endDate, startDate} = props;
  // function handleDownloadExcel() {
  //  const apiresp = await dispatch(api({
  //     endDate, startDate
  //     })
  //   )
  //   downloadExcel({
  //     fileName: tableName ? tableName : "Shyamg Data Record",
  //     sheet: tableName ? tableName : "Shree Shyam Snacks Food Pvt. Ltd.",
  //     tablePayload: {
  //       header: header,
  //       body: body,
  //     },
  //   });
  // }
  async function handleDownloadExcel() {
    try {
      const apiresp = await dispatch(
        api({
          endDate, 
          startDate,
        })
      );
      if (exportRecord?.length && apiresp?.payload?.status) {
        downloadExcel({
          fileName: tableName || "Ranbazaar",
          sheet: tableName || "Ranbazaar",
          tablePayload: {
            header: header,
            body: body,
          },
        });
      } else {
        console.error("API response is invalid or empty");
      }
    } catch (error) {
      console.error("Error downloading Excel:", error);
    }
  }
  
  return (
    <div>
      <Button onClick={handleDownloadExcel} className="f-right btn btn-success">
        {title}
      </Button>
    </div>
  );
};

export default ExportData;
