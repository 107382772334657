import React, { useEffect, useState } from "react";
import { Col, Container, Row, Tab, Nav } from "react-bootstrap";
import { Link, useNavigate, useSearchParams } from "react-router-dom"; // useSearchParams added
import { home, shop, userDashboard } from "../../../const";
import CommonHeader from "../../Sub/CommonHeader";
import UserOrders from "./UserOrders";
import UserProfile from "./UserProfile";
import UserReview from "./UserReview";
import UserWishList from "./UserWishList";
import UserAddress from "./UserAddress";

const UserPanel = () => {
  const [searchParams] = useSearchParams(); // Get search params
  const eventKeytab = searchParams.get("eventKeytab"); // Get eventKeytab from URL

  const navigate = useNavigate();

  // State to track the active tab
  const [tabState, setTabState] = useState(eventKeytab || "first");

  // Update tabState when eventKeytab changes in the URL
  useEffect(() => {
    if (eventKeytab) {
      setTabState(eventKeytab); // Set the current tab state based on query param
    }
  }, [eventKeytab]); // Trigger when eventKeytab changes

  return (
    <>
      <CommonHeader />
      <div className="container-fluid">
        <Row className="mt-3">
          <Container>
            <div className="section__head d-md-flex justify-content-between mb-40">
              <div className="section__title">
                <h2>
                  <b>My Dashboard</b>
                </h2>
              </div>
            </div>
          </Container>
        </Row>
        <Row>
          <Tab.Container activeKey={tabState}>
            <Row>
              <Col sm={2}>
                <Nav variant="pills" className="flex-column">
                  <Link to={home} aria-label="Go to Home">
                    <Nav.Item className="tab-btn">
                      <p className="font-18 mb-0">
                        <i className="bi bi-house-door-fill"></i> Home
                      </p>
                    </Nav.Item>
                  </Link>
                  <Link to={shop} aria-label="Go to Shopping">
                    <Nav.Item className="tab-btn">
                      <p className="font-18 mb-0">
                        <i className="bi bi-shop"></i> Shopping
                      </p>
                    </Nav.Item>
                  </Link>

                  <Nav.Item>
                    <Nav.Link
                      className="tab-btn"
                      eventKey="first"
                      onClick={() =>
                        navigate(`${userDashboard}?eventKeytab=first`)
                      }
                    >
                      <i className="bi bi-person-fill"></i> My Profile
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      className="tab-btn"
                      eventKey="second"
                      onClick={() =>
                        navigate(`${userDashboard}?eventKeytab=second`)
                      }
                    >
                      <i className="bi bi-cart-check-fill"></i> My Orders
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      className="tab-btn"
                      eventKey="forth"
                      onClick={() =>
                        navigate(`${userDashboard}?eventKeytab=forth`)
                      }
                    >
                      <i className="bi bi-pencil-square"></i> Review Product
                    </Nav.Link>
                  </Nav.Item>
                  {/* <Nav.Item>
                    <Nav.Link
                      className="tab-btn"
                      eventKey="fifth"
                      onClick={() =>
                        navigate(`${userDashboard}?eventKeytab=fifth`)
                      }
                    >
                      <i className="bi bi-pencil-square"></i> Address
                    </Nav.Link>
                  </Nav.Item> */}
                </Nav>
              </Col>
              <Col sm={10}>
                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    <UserProfile />
                  </Tab.Pane>
                  <Tab.Pane eventKey="second">
                    <UserOrders />
                  </Tab.Pane>
                  <Tab.Pane eventKey="third">
                    <UserWishList />
                  </Tab.Pane>
                  <Tab.Pane eventKey="forth">
                    <UserReview />
                  </Tab.Pane>
                  {/* <Tab.Pane eventKey="fifth">
                    <UserAddress />
                  </Tab.Pane> */}
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Row>
      </div>
    </>
  );
};

export default UserPanel;
